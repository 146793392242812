export default [
  {
    // NOTE: Baptism is the default theme
    themeName: 'Baptism',
    light: {
      themeName: 'Baptism',
      colors: {
        primary: '#424242',
        secondary: '#505050',
        error: '#ff8900', // e0a0a9
        info: '#2196F3',
        success: '#07630b',
        button: '#1d729a',
        toolbar: '#e9f4f9',
        warning: '#ac6202',
        header: '#2C2C2C',
        subheader: '#333333',
        cardBackground: '#ffffff',
        cardBorderColor: '#ccc'
      }
    },
    dark: {
      themeName: 'Baptism',
      colors: {
        primary: '#bdbdbd',
        secondary: '#73d8d9',
        error: '#ff8900', //  F1413BD6
        info: '#249caf',
        success: '#1bb922',
        button: '#158ec8',
        toolbar: '#3b748e',
        warning: '#eae5ad',
        header: '#73d8d9',
        subheader: '#73d8d9',
        cardBackground: '#66666699',
        cardBorderColor: '#73d8d9'
      }
    }
  },
  {
    themeName: 'Bethlehem',
    light: {
      themeName: 'Bethlehem',
      colors: {
        primary: '#595959',
        secondary: '#666666',
        error: '#cb4760',
        info: '#2196F3',
        success: '#07630b',
        warning: '#824900',
        header: '#666666',
        subheader: '#666666',
        cardBackground: '#fecc8f',
        cardBorderColor: '#666666'
      }
    },
    dark: {
      themeName: 'Bethlehem',
      colors: {
        primary: '#dfdfdf',
        secondary: '#F0B165FF',
        error: '#6e0216',
        info: '#249caf',
        success: '#1bb922',
        warning: '#d2b616',
        header: '#f0b165',
        subheader: '#f0b165',
        cardBackground: '#666666',
        cardBorderColor: '#f0b165'
      }
    }
  },
  {
    themeName: 'Christmas',
    light: {
      themeName: 'Christmas',
      colors: {
        primary: '#222222',
        secondary: '#235E6F',
        error: '#6e0216',
        info: '#3b8fee',
        success: '#1eb026',
        warning: '#797434',
        header: '#ff0000',
        subheader: '#ff0000',
        cardBackground: '#70cf76',
        cardBorderColor: '#235E6F'
      }
    },
    dark: {
      themeName: 'Christmas',
      colors: {
        primary: '#ffdfdf',
        secondary: '#CC231E',
        error: '#6e0216',
        info: '#249caf',
        success: '#1bb922',
        warning: '#d2b616',
        header: '#235E6F',
        subheader: '#235E6F',
        cardBackground: '#0F8A5F',
        cardBorderColor: '#235E6F'
      }
    }
  },
  {
    themeName: 'Holy',
    light: {
      themeName: 'Holy',
      colors: {
        primary: '#0e2945',
        secondary: '#1f66c1',
        error: '#6e0216',
        info: '#2196F3',
        success: '#07630b',
        warning: '#824900',
        header: '#1f66c1',
        subheader: '#1f66c1',
        cardBackground: '#fff983',
        cardBorderColor: '#1f66c1'
      }
    },
    dark: {
      themeName: 'Holy',
      colors: {
        primary: '#fffcfc',
        secondary: '#e3b815',
        error: '#6e0216',
        info: '#249caf',
        success: '#1bb922',
        warning: '#d2b616',
        header: '#e3b815',
        subheader: '#e3b815',
        cardBackground: '#2d6078',
        cardBorderColor: '#e3b815'
      }
    }
  },
  {
    themeName: 'Sunday',
    light: {
      themeName: 'Sunday',
      colors: {
        primary: '#666',
        secondary: '#847a67',
        error: '#6e0216',
        info: '#2196F3',
        success: '#07630b',
        warning: '#824900',
        header: '#847a67',
        subheader: '#847a67',
        cardBackground: '#e3dca2',
        cardBorderColor: '#847a67'
      }
    },
    dark: {
      themeName: 'Sunday',
      colors: {
        primary: '#fffef6',
        secondary: '#e3dca2',
        error: '#6e0216',
        info: '#249caf',
        success: '#1bb922',
        warning: '#d2b616',
        header: '#e3dca2',
        subheader: '#e3dca2',
        cardBackground: '#847a67',
        cardBorderColor: '#e3dca2'
      }
    }
  }
]
