<template>
  <v-theme-provider>
    <v-app id="app">
      <router-view class="h-100 w-100"></router-view>
      <ChangelogViewer v-model:is-changelog-dialog-open="isChangelogDialogOpen" />
    </v-app>
  </v-theme-provider>
</template>

<script setup>
import stores from '@/stores'
import { onMounted, ref } from 'vue'
import ChangelogViewer from '@/components/ChangelogViewer.vue'
import { ampli } from '@/ampli/index.js'

const isChangelogDialogOpen = ref(false)

// METHODS
const onVersionCombinationKeyUp = (event) => {
  if (event.ctrlKey && event.altKey && event.code === 'KeyV') {
    isChangelogDialogOpen.value = true
  }
}

// LIFECYCLE
onMounted(() => {
  window.addEventListener('keydown', onVersionCombinationKeyUp)
  stores.useAppStore().setAppVersion()

  ampli.client.setGroup('webId', stores.useOrganizationStore().webId)
})
</script>
