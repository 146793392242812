<template>
  <v-dialog :model-value="dialogOpen" :scrim="true" :contained="true" persistent class="justify-center align-center">
    <v-card v-if="!formSubmitted" height="auto" class="mx-auto" width="auto" data-test-id="forgot-password-form-card">
      <div class="pt-2 my-2 mx-6 text-h6">
        {{ title }}
      </div>
      <div class="pt-2 my-2 mx-6 text-h6">
        {{ message }}
      </div>

      <Form
        v-slot="{ meta }"
        as="v-form"
        :validation-schema="validationSchema"
        class="my-3 mx-6"
        data-test-id="forgot-password-form"
        @submit="sendPasswordResetLink"
      >
        <Field v-slot="{ field, errors }" v-model="email" name="email">
          <v-text-field
            v-bind="field"
            :readonly="emailReadOnly"
            :model-value="email"
            :error-messages="errors"
            :hint="$t('login.emailHint')"
            density="compact"
            hide-details
            variant="outlined"
          />
        </Field>
        <v-btn
          :disabled="(!meta.valid && meta.dirty) || pending"
          color="button"
          class="mr-4 mt-6"
          block
          :loading="pending"
          type="submit"
        >
          {{ $t('forgotPassword.sendPasswordReset') }}
        </v-btn>

        <v-row dense class="my-3 mx-6">
          <v-btn block variant="plain" @click="closeDialog">
            {{ $t('common.cancel') }}
          </v-btn>
        </v-row>
      </Form>
    </v-card>

    <v-card
      v-if="formSubmitted"
      class="mx-auto"
      max-width="400"
      color="cardBackground"
      data-test-id="forgot-password-attempted-message-card"
    >
      <v-row dense class="my-3 mx-6">
        <v-col>
          <v-alert :type="informationCardAlertType" data-test-id="forgot-password-message-card-alert">{{
            emailAttemptedMessage
          }}</v-alert>
        </v-col>
      </v-row>
      <v-row dense class="my-3 mx-6">
        <v-col>
          <v-btn block color="primary" @click="closeDialog">
            {{ $t('common.close') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script setup>
import * as yup from 'yup'
import stores from '@/stores'
import { computed, onBeforeUpdate, ref } from 'vue'
import { Form, Field } from 'vee-validate'
import { useI18n } from 'vue-i18n'

const { t: $t } = useI18n()

// PROPS

const props = defineProps({
  dialogUserType: {
    // MEMBER / STAFF - Allows us to control if the email field to read only or not for a user vs. a staff member reset.
    type: String,
    required: true
  },
  emailReadOnly: {
    type: Boolean
  },
  initialEmail: {
    type: String,
    required: true,
    default: ''
  },
  isDialogOpen: {
    type: Boolean
  },
  message: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  webId: {
    type: String,
    default: ''
  }
})

//REFS

const email = ref(''),
  formSubmitted = ref(false),
  resetEmailSentSuccessful = ref(false),
  pending = ref(false),
  emailAttemptedMessage = ref('')

// EMITS

const emit = defineEmits(['update:isDialogOpen', 'closeDialog'])

// COMPUTED

const dialogOpen = computed({
  get() {
    return props.isDialogOpen
  },
  set(value) {
    emit('update:isDialogOpen', value)
  }
})

const informationCardAlertType = computed(() => {
  return resetEmailSentSuccessful.value ? 'success' : 'error'
})

const validationSchema = computed(() => {
  return yup.object({
    email: yup.string().required($t('validations.staffProfile.email')).email()
  })
})

// METHODS

const closeDialog = () => {
  formSubmitted.value = false
  dialogOpen.value = false
}

const sendPasswordResetLink = async () => {
  pending.value = true
  const payload = {
    webid: props.webId,
    email: email.value,
    userType: props.dialogUserType
  }

  const result = await stores.useSessionStore().sendPasswordResetEmail(payload)
  if (result?.data?.success) {
    resetEmailSentSuccessful.value = true
    emailAttemptedMessage.value =
      props.dialogUserType === 'staff' ? $t('forgotPassword.successStaffLoginPage') : $t('forgotPassword.successMember')
  } else {
    resetEmailSentSuccessful.value = false
    emailAttemptedMessage.value = result?.data?.message
  }
  formSubmitted.value = true

  pending.value = false
}

//LIFECYCLE

onBeforeUpdate(() => {
  email.value = props.initialEmail
})
</script>
