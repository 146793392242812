import Proxy from './Proxy'

/**
 * Accounts in the context is giving fund accounts
 */
export default class AccountsProxy extends Proxy {
  /**
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super(`${import.meta.env.VITE_GIVING_API_BASE_URL}/v1`, parameters)
  }

  /**
   * * Get all accounts
   *
   * @returns {Promise}
   */
  async getAccounts() {
    return this.submit('get', `${this.endpoint}/accounts`)
  }

  /**
   * * Get scheduled gift accounts
   *
   * @returns {Promise}
   */
  async getScheduledGiftAccounts(webId) {
    return this.submit('get', `${this.endpoint}/accounts/scheduled/${webId}`)
  }

  /**
   * * Get all accounts for mobile giving
   *
   *
   *  @param webId
   * @returns {Promise}
   */
  async getMobileGivingAccounts(webId) {
    return this.submit('get', `${this.endpoint}/accounts/mobile/${webId}`)
  }

  /**
   * * New account
   *
   *  @param payload
   * @returns {Promise}
   */
  async createAccount(payload) {
    return this.submit('post', `${this.endpoint}/accounts`, payload)
  }

  /**
   * * Update account
   *
   *  @param payload
   *  @param newScheduledGiftsAccount
   *  @returns {Promise}
   *
   * @description Updates an account. if the account is being deactivated
   * and has scheduled gifts, we pass the newScheduledGiftsAccount so that the
   * scheduled gifts can be updated with the new account
   */
  async updateAccount(payload, newScheduledGiftsAccount) {
    return this.submit('patch', `${this.endpoint}/accounts/${payload.acct_id}`, { payload, newScheduledGiftsAccount })
  }
}
