import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@/styles/index.scss'
import customThemes from '@/theme'

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, md } from 'vuetify/iconsets/md'
import { aliases as mdiAliases, mdi } from 'vuetify/iconsets/mdi'

let userThemePreference
const initialTheme = customThemes.find((theme) => {
  return theme.themeName === 'Baptism'
})

const storedThemeName = localStorage.getItem('theme')

if (storedThemeName) {
  userThemePreference = customThemes.find((theme) => {
    return theme.themeName === storedThemeName
  })
}

export default createVuetify({
  icons: {
    defaultSet: 'md',
    aliases: {
      ...mdiAliases,
      ...aliases
    },
    sets: {
      md,
      mdi
    }
  },
  components,
  directives,
  theme: {
    themes: userThemePreference || initialTheme,
    defaultTheme: localStorage.getItem('theme_variant') || 'light'
  }
})
