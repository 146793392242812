<template>
  <div>
    <v-app-bar elevation="0" class="border-b">
      <v-app-bar-nav-icon
        v-if="authenticated"
        class="d-sm-flex d-lg-none"
        variant="text"
        icon="menu"
        @click="isDrawerOpen = !isDrawerOpen"
      />
      <span class="text-h5">{{ churchName }}</span>
      <v-spacer />
      <v-btn
        v-if="showGiveButton"
        class="mr-4 ml-4 d-none d-md-flex"
        size="small"
        variant="flat"
        color="button"
        :to="{ name: 'giving' }"
      >
        <template #prepend>
          <i class="mdi mdi-hand-heart d-flex align-center" style="font-size: 1.5em" aria-hidden="true"></i>
        </template>
        {{ $t('giving.cardButtonText') }}
      </v-btn>
      <UserProfileSection v-if="authenticated" />
      <div v-if="!authenticated" class="login-signup-button-container">
        <v-btn
          v-if="showLoginButton"
          class="mr-4 login"
          size="small"
          variant="flat"
          color="button"
          data-test-id="member-header-login-button"
          @click="signIn"
        >
          {{ $t('login.submitBtnLabel') }}
        </v-btn>
        <v-btn v-if="showSignupButton" size="small" variant="flat" color="button" :to="{ name: 'member.signup' }">
          {{ $t('login.signUp') }}
        </v-btn>
      </div>
    </v-app-bar>
    <MemberMobileNav v-model:is-drawer-open="isDrawerOpen" class="d-sm-flex d-lg-none" />
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import stores from '@/stores'
import MemberMobileNav from '@/components/nav/MemberMobileNav.vue'
import UserProfileSection from '@/components/header/components/UserProfileSection.vue'
import { ampli } from '@/ampli/index.js'
import { storeToRefs } from 'pinia'

const route = useRoute()
const authenticated = stores.useSessionStore().isAuthenticated
const churchName = stores.useOrganizationStore().getOrgName
const isDrawerOpen = ref(false)
const group = ref(null)
const router = useRouter()
const userStore = stores.useUserStore()
const { profileData } = storeToRefs(userStore)

//COMPUTED
const showGiveButton = computed(() => {
  return authenticated && !route.fullPath.includes('giving')
})
const showSignupButton = computed(() => {
  return !route.fullPath.includes('signup')
})
const showLoginButton = computed(() => {
  return route.name !== 'member.login'
})

// WATCHERS
watch(group, () => {
  isDrawerOpen.value = false
})

// METHODS
const signIn = () => {
  ampli.signInButtonClick({
    email: profileData.value.portalemail
  })
  router.push({ name: 'member.login' })
}
</script>

<style scoped lang="scss">
header {
  display: flex;

  .v-img,
  span {
    margin-left: 16px;
  }

  .text-h5 {
    @media (max-width: 1024px) {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}

.login-btn {
  margin-left: 16px;
  @media (min-width: 1024px) {
    display: none;
  }
}

.login-signup-button-container {
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}
</style>
