import axios from 'axios'
import { snakeCase } from '@/utils/string.utils'

const isDevMode = import.meta.env.VITE_ENV === 'development'

class UnsplashBaseProxy {
  /**
   * The constructor of the UnsplashBaseProxy.
   *
   * @param {string} endpoint   The endpoint being used.
   * @param {Object} parameters The parameters for the request.
   */
  constructor(endpoint, parameters = {}) {
    this.endpoint = endpoint
    this.parameters = parameters
  }

  showConsoleLogInDevMode(message, data) {
    if (isDevMode) {
      console.log(message, data)
    }
  }

  /**
   * Method used to set the query parameters. all camelCase params are converted to snake_case
   *
   * @param {Object} parameters The given parameters.
   *
   * @returns {UnsplashBaseProxy} The instance of the proxy.
   */
  setParameters(parameters) {
    Object.keys(parameters).forEach((key) => {
      this.parameters[snakeCase(key)] = parameters[key]
    })

    return this
  }

  /**
   * Method used to set a single parameter.
   *
   * @param {string} parameter The given parameter.
   * @param {*} value The value to be set.
   *
   * @returns {UnsplashBaseProxy} The instance of the proxy.
   */
  setParameter(parameter, value) {
    this.parameters[parameter] = value

    return this
  }

  /**
   * Method used to remove all the parameters.
   *
   * @param {Array} parameters The given parameters.
   *
   * @returns {UnsplashBaseProxy} The instance of the proxy.
   */
  removeParameters(parameters) {
    parameters.forEach((parameter) => {
      delete this.parameters[parameter]
    })

    return this
  }

  /**
   * Method used to remove a single parameter.
   *
   * @param {string} parameter The given parameter.
   *
   * @returns {UnsplashBaseProxy} The instance of the proxy.
   */
  removeParameter(parameter) {
    delete this.parameters[parameter]

    return this
  }

  /**
   * The method used to perform an AJAX-request.
   *
   * @param {string}      requestType The request type.
   * @param {string}      url         The URL for the request.
   * @param {Object|null} data        The data to be send with the request.
   *
   * @returns {Promise} The result in a promise.
   */
  submit(requestType, url, data = null) {
    return new Promise((resolve, reject) => {
      this.showConsoleLogInDevMode('[Proxy] submit:', requestType, url, data)
      this.showConsoleLogInDevMode('[Proxy]', url + this.getParameterString())
      const start = performance.now()
      const authToken = import.meta.env.VITE_UNSPLASH_ACCESS_KEY

      if (authToken) {
        // sets the Unsplash API access token into the header
        axios.defaults.headers.common = { Authorization: `CLIENT-ID ${authToken}` }
      }
      axios[requestType](url + this.getParameterString(), data)
        .then((response) => {
          const end = performance.now()
          this.showConsoleLogInDevMode(`Perf\t${url}\tsuccess\t${end - start}`)
          this.showConsoleLogInDevMode('[Proxy] response', response)
          if (response) {
            resolve(response)
          } else {
            resolve('no response')
          }
        })
        .catch((err) => {
          const end = performance.now()
          this.showConsoleLogInDevMode(`Perf\t${url}\tfailure\t${end - start}`)
          this.showConsoleLogInDevMode('[Proxy] error', err.response)
          if (err) {
            return reject(err.response)
          } else {
            return reject(new Error(`Error fetching data. ${requestType} ${url}`))
          }
        })
    })
  }

  /**
   * Method used to transform a parameters object to a parameters string.
   *
   * @returns {string} The parameter string.
   */
  getParameterString() {
    const keys = Object.keys(this.parameters)
    const parameterStrings = keys
      .filter((key) => !!this.parameters[key])
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(this.parameters[key])}`)
    return parameterStrings.length === 0 ? '' : `?${parameterStrings.join('&')}`
  }
}

export default UnsplashBaseProxy
