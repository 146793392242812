<template>
  <div class="Vue-Toastification__toast--warning">
    <div class="toast-content">{{ $t('giving.expiredCardMessage') }}</div>
    <div class="update-button d-flex justify-end">
      <button
        class="v-btn v-btn--elevated v-theme--light bg-blue v-btn--density-default v-btn--size-default v-btn--variant-elevated secondary sk-btn mr-4 mt-3"
        @click.stop="$router.push({ name: 'member.paymentmethods' })"
      >
        <span class="v-btn__content">{{ $t('giving.expiredCardUpdateButton') }}</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.toast-content {
  color: #666 !important;
}

.update-button {
  display: flex;
  justify-content: start;
  align-content: center;

  button {
    background-color: #666 !important;
    font-weight: 500;
    text-transform: none;
  }
}
</style>
