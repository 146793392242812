<template>
  <v-navigation-drawer dark prominent app class="d-block">
    <v-list density="compact">
      <v-list-item
        :to="{ name: 'member.index' }"
        prepend-icon="home"
        :title="$t('navigation.member.home')"
        color="button"
        :active="selectedNav === 'home'"
      />
      <v-list-item
        :to="{ name: 'member.scheduled' }"
        prepend-icon="autorenew"
        :title="$t('navigation.member.recurring')"
        color="button"
        :active="selectedNav === 'scheduled'"
      />
      <v-list-item
        :to="{ name: 'member.paymentmethods' }"
        prepend-icon="payment"
        :title="$t('navigation.member.paymentmethods')"
        color="button"
        :active="selectedNav === 'paymentmethods'"
      />
      <v-list-item
        :to="{ name: 'giving' }"
        :title="$t('navigation.member.give')"
        color="button"
        :active="selectedNav === 'giving'"
      >
        <template #prepend>
          <i class="mdi mdi-hand-heart material-icons v-icon d-flex align-center" aria-hidden="true"></i>
        </template>
      </v-list-item>
    </v-list>
    <div class="app-version">v{{ appVersion }}</div>
  </v-navigation-drawer>
</template>

<script setup>
import { useSelectedMemberNav } from '@/composables/nav/useSelectedMemberNav'
import { computed } from 'vue'
import stores from '@/stores'

const { selectedRoute: selectedNav } = useSelectedMemberNav()

// COMPUTED
const appVersion = computed(() => stores.useAppStore().getVersion)
</script>

<style scoped lang="scss">
.app-version {
  font-size: 0.7rem;
  padding-top: 1rem;
  margin-left: 1.5rem;
}
</style>
