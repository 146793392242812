<template>
  <v-navigation-drawer
    v-model="drawerOpen"
    location="left"
    floating
    sticky
    temporary
    :class="{ 'd-none': !drawerOpen }"
    dark
  >
    <v-list density="compact">
      <v-list-item
        prepend-icon="home"
        :title="$t('navigation.staff.dashboard')"
        color="button"
        :active="selectedNav === 'home'"
        @click="navigate({ name: 'dashboard.index' })"
      />
      <v-list-item
        prepend-icon="wallet_giftcard"
        :title="$t('navigation.staff.gifts')"
        color="button"
        :active="selectedNav === 'gifts'"
        @click="navigate({ name: 'gifts.index' })"
      />
      <v-list-item
        prepend-icon="autorenew"
        :title="$t('navigation.staff.recurring')"
        color="button"
        :active="selectedNav === 'scheduled'"
        @click="navigate({ name: 'scheduled.index' })"
      />
      <v-list-item
        prepend-icon="group"
        :title="$t('navigation.staff.givers')"
        color="button"
        :active="selectedNav === 'givers'"
        @click="navigate({ name: 'givers.index' })"
      />
      <v-list-item
        prepend-icon="money"
        :title="$t('navigation.staff.deposits')"
        color="button"
        :active="selectedNav === 'deposits'"
        @click="navigate({ name: 'deposits.index' })"
      />
      <v-list-item
        :to="{ name: 'accounts.index' }"
        prepend-icon="account_balance"
        :title="$t('navigation.staff.accounts')"
        color="button"
        :active="selectedNav === 'accounts'"
      />
      <v-list-item
        prepend-icon="dashboard_customize"
        :title="$t('navigation.staff.givingForm')"
        color="button"
        :active="selectedNav === 'giving-forms'"
        @click="navigate({ name: 'giving-forms.editor' })"
      />
      <!--
      <v-list-item
        prepend-icon="qr_code"
        :title="$t('navigation.staff.shareGivingForm')"
        color="button"
        :active="selectedNav === 'sharing'"
        @click="navigate({ name: 'giving-forms.share' })"
      /> -->
      <v-list-item
        :to="{ name: 'users.index' }"
        prepend-icon="group"
        :title="$t('navigation.staff.staffmanagement')"
        color="button"
        :active="selectedNav === 'users-list'"
      />
      <v-list-item
        :to="{ name: 'settingsorg' }"
        prepend-icon="settings"
        :title="$t('navigation.staff.settingsOrg')"
        color="button"
        :active="selectedNav === 'settingsorg'"
      />
      <v-list-item
        prepend-icon="manage_accounts"
        :title="$t('navigation.staff.settings')"
        color="button"
        :active="selectedNav === 'settings'"
        @click="openProfileEditDialog"
      />
      <v-list-item
        class="logout"
        prepend-icon="logout"
        :title="$t('navigation.staff.logout')"
        color="button"
        @click="logout"
      />
    </v-list>
    <div class="app-version">v{{ appVersion }}</div>
    <ProfileEditDialog v-model:is-profile-dialog-open="isProfileDialogOpen" />
  </v-navigation-drawer>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import stores from '@/stores'
import ProfileEditDialog from '@/components/profile-edit-dialog/ProfileEditDialog.vue'
import { useSelectedStaffNav } from '@/composables/nav/useSelectedStaffNav'
import { useI18n } from 'vue-i18n'

const router = useRouter()
const $t = useI18n().t
const sessionStore = stores.useSessionStore()
const isProfileDialogOpen = ref(false),
  group = ref(null),
  { selectedRoute: selectedNav } = useSelectedStaffNav()

// PROPS
const props = defineProps({
  isDrawerOpen: {
    type: Boolean
  }
})

// WATCHERS
watch(group, () => {
  isProfileDialogOpen.value = false
})

// EMITS
const emit = defineEmits(['update:isDrawerOpen'])

// COMPUTED
const appVersion = computed(() => stores.useAppStore().getVersion)

const drawerOpen = computed({
  get() {
    return props.isDrawerOpen
  },
  set(value) {
    emit('update:isDrawerOpen', value)
  }
})

// METHODS
const openProfileEditDialog = () => {
  isProfileDialogOpen.value = true
}

const logout = async () => {
  await sessionStore.logout()
  stores.useToastStore().showSuccessToast($t('navigation.logoutMessage'))
  await router.push({ name: `login.index` })
}

const navigate = (to) => {
  drawerOpen.value = false
  router.push(to)
}
</script>

<style scoped lang="scss">
.logout {
  cursor: pointer;
}

.app-version {
  font-size: 0.7rem;
  margin-top: 1rem;
  margin-left: 1.5rem;
}
</style>
