import RecaptchaProxy from '@/proxy/RecaptchaProxy'

export default {
  /**
   *
   * @param {string} token
   * @returns {Promise}
   */
  async validateRecaptchaToken(token) {
    const proxy = new RecaptchaProxy()
    try {
      const response = await proxy.validateRecaptchaToken(token)
      this.isValidated = response.data.valid
      return response.data
    } catch (error) {
      return { success: false, error }
    }
  }
}
