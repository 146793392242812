export default () => ({
  batchDepositData: {
    selectedDeposit: {
      batchDate: ''
    }
  },
  paymentMethodComponentData: {
    paymentMethodConfigId: '',
    sessionKey: ''
  }
})
