import statesData from '@/assets/data/states.json'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(timezone)

export default () => ({
  giverRecordData: null,
  paymentMethodData: null,
  originalProfileData: {
    first_name: '',
    last_name: '',
    email1: '',
    portalemail: '',
    zip: '',
    c_phone: '',
    online: 2
  }, // copy of the profile data as it comes from the API the first time
  profileData: {
    first_name: '',
    last_name: '',
    email1: '',
    portalemail: '',
    zip: '',
    c_phone: '',
    online: 2
  },
  states: statesData,
  userProfileStates: [],
  userTimezone: dayjs.tz.guess()
})
