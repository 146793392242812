<template>
  <div>
    <v-app-bar elevation="0" class="border-b">
      <v-app-bar-nav-icon
        v-if="authenticated"
        class="d-sm-flex d-lg-none"
        variant="text"
        icon="menu"
        @click="isDrawerOpen = !isDrawerOpen"
      />
      <v-img
        width="184"
        height="28"
        :src="logoUrl"
        class="logo flex-shrink-1 flex-grow-0"
        :style="
          $vuetify.theme.current.dark
            ? 'filter: invert(1); -webkit-filter: invert(1)'
            : 'filter: invert(0); -webkit-filter: invert(0)'
        "
      />
      <span class="text-h5 d-none d-md-flex flex-grow-1 flex-shrink-0">{{ $t('common.appTitle') }}</span>
      <v-spacer />
      <ThemeToggle class="d-lg-none" />
      <UserProfileSection v-if="authenticated" />
    </v-app-bar>
    <StaffMobileNav v-model:is-drawer-open="isDrawerOpen" />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import stores from '@/stores'
import StaffMobileNav from '@/components/nav/StaffMobileNav.vue'
import UserProfileSection from '@/components/header/components/UserProfileSection.vue'
import ThemeToggle from '@/components/theme/ThemeToggle.vue'

const authenticated = stores.useSessionStore().isAuthenticated
const logoUrl = new URL('@/assets/images/sk-logo.png', import.meta.url).href
const isDrawerOpen = ref(false)
const group = ref(null)

// WATCHERS
watch(group, () => {
  isDrawerOpen.value = false
})
</script>

<style scoped lang="scss">
header {
  display: flex;

  .v-img,
  span {
    margin-left: 16px;
  }

  .text-h5 {
    @media (max-width: 1024px) {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
</style>
