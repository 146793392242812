import useConsoleLogHelper from '@/helpers/consoleLogHelper'
import UnsplashImagesProxy from '@/proxy/UnsplashImagesProxy'
import sentryErrorLoggingHelper from '@/helpers/sentryErrorLoggingHelper'

const { showConsoleLogInDevMode } = useConsoleLogHelper()

export default {
  async fetchImages(payload) {
    const result = {
      success: true,
      message: null
    }
    let response = null

    try {
      const proxy = new UnsplashImagesProxy()
      response = await proxy.searchPhotos(payload)
      const imgResults = response.data

      if (!response?.errors) {
        result.success = true

        if (imgResults.response.results.length !== 0) {
          this.images = imgResults.response.results
        } else {
          result.success = false
          result.message = 'NO_RESULTS'
        }
      } else if (response?.errors) {
        sentryErrorLoggingHelper().sentryErrorWithContext(response?.errors)
      }
    } catch (error) {
      result.success = false
      result.message = error
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }

    return result
  },

  setSelectedImage({ image }) {
    this.selectedImage = image
  },

  async trackDownload({ image }) {
    const proxy = new UnsplashImagesProxy()
    const response = await proxy.trackDownload(image.links)
    showConsoleLogInDevMode('Unsplash download tracking result: ', response)
  }
}
