/* ============
 * Axios
 * ============
 *
 * Promise based HTTP client for the browser and node.js.
 * Because Vue Resource has been retired, Axios will now been used
 * to perform AJAX-requests.
 *
 * https://github.com/mzabriskie/axios
 */

import axios from 'axios'
import router from '@/router/index'
import { LOGIN } from '@/constants/apiConstants.js'
import stores from '@/stores'
import sentryErrorLoggingHelper from '@/helpers/sentryErrorLoggingHelper'

axios.defaults.headers.common.Accept = 'application/json'

const Error400ResponseUrlExclusions = [
  `${import.meta.env.VITE_GIVING_API_BASE_URL}/v1/payments/tokensale`,
  `${import.meta.env.VITE_GIVING_API_BASE_URL}/v1/profile/supermegachurch/mobilegiving/profile`
]

const logoutUser = async () => {
  const loginRoute = {
    name: router.currentRoute.value.fullPath.includes('member') ? 'member.login' : 'login.index'
  }
  await stores.useSessionStore().logout()
  await router.replace(loginRoute) // suppress duplicate route errors
}

// set up an axios response interceptor so that if there are invalid tokens or api exceptions, we can redirect to the appropriate page or display an appropriate error
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 403 && error?.request?.responseURL.indexOf('/v1/forgot/check_member') !== -1) {
      // user is trying to change password, but the old password is wrong
      return Promise.resolve(error)
    } else if (
      // if the request is expected as login failure or invalid/expired token, redirect to log in
      error?.response?.data?.message === 'Invalid Token' ||
      error?.response?.status === 401 ||
      (error?.response?.status === 403 && !router.currentRoute.value.fullPath.includes('login'))
    ) {
      logoutUser()
    } else if (error?.response?.status === 403 && router.currentRoute.value.fullPath.includes('login')) {
      // invalid login, don't log
      if (error?.response?.data?.message === LOGIN.EMAIL_NOT_VERIFIED) {
        return Promise.resolve(error)
      }
      return Promise.reject(error)
    } else {
      if (
        error?.response?.status !== 403 &&
        error?.response?.status !== 404 &&
        !Error400ResponseUrlExclusions.includes(error.request.responseURL)
      ) {
        console.log(error.message || error?.response?.data?.message)
        sentryErrorLoggingHelper().sentryErrorWithContext(error)
      }
    }

    return Promise.reject(error)
  }
)

// Interceptor adds API key and auth token (where applicable) to header
axios.interceptors.request.use((config) => {
  const authToken = localStorage.getItem('sko_token')
  const smsToken = stores.useSessionStore().getSmsToken
  const smsPhone = stores.useSessionStore().getSmsPhone
  const isSkApi =
    config.url.indexOf(import.meta.env.VITE_GIVING_API_BASE_URL) !== -1 ||
    config.url.indexOf(import.meta.env.VITE_ACCOUNT_API_BASE_URL) !== -1

  if (isSkApi) {
    const headers = {
      ...config.headers,
      'x-api-key': import.meta.env.VITE_API_KEY,
      Authorization: authToken ? `Bearer ${authToken}` : undefined
    }

    if (smsToken) {
      headers['x-sms-phone'] = smsPhone
      headers['x-sms-token'] = smsToken
    } else {
      headers['x-sms-phone'] = undefined
      headers['x-sms-token'] = undefined
    }

    return {
      ...config,
      headers
    }
  } else {
    return config
  }
})
