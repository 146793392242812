<template>
  <v-navigation-drawer
    v-model="drawerOpen"
    location="left"
    floating
    sticky
    temporary
    dark
    :class="{ 'd-none': !drawerOpen }"
  >
    <v-list density="compact">
      <v-list-item
        prepend-icon="home"
        :title="$t('navigation.member.home')"
        color="button"
        :active="selectedNav === 'home'"
        @click="navigate({ name: 'member.index' })"
      />
      <v-list-item
        prepend-icon="autorenew"
        :title="$t('navigation.member.recurring')"
        color="button"
        :active="selectedNav === 'scheduled'"
        @click="navigate({ name: 'member.scheduled' })"
      />
      <v-list-item
        prepend-icon="payment"
        :title="$t('navigation.member.paymentmethods')"
        color="button"
        :active="selectedNav === 'paymentmethods'"
        @click="navigate({ name: 'member.paymentmethods' })"
      />
      <v-list-item
        :to="{ name: 'giving' }"
        :title="$t('navigation.member.give')"
        color="button"
        :active="selectedNav === 'giving'"
      >
        <template #prepend>
          <i class="mdi mdi-hand-heart material-icons v-icon d-flex align-center" aria-hidden="true"></i>
        </template>
      </v-list-item>
      <v-list-item
        prepend-icon="settings"
        :title="$t('navigation.member.settings')"
        color="button"
        :active="selectedNav === 'settings'"
        @click="openProfileEditDialog"
      />
      <v-list-item
        class="logout"
        prepend-icon="logout"
        :title="$t('navigation.member.logout')"
        color="button"
        @click="logout"
      />
    </v-list>
    <div class="app-version">v{{ appVersion }}</div>
    <ProfileEditDialog v-model:is-profile-dialog-open="isProfileDialogOpen" />
  </v-navigation-drawer>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import stores from '@/stores'
import ProfileEditDialog from '@/components/profile-edit-dialog/ProfileEditDialog.vue'
import { useSelectedMemberNav } from '@/composables/nav/useSelectedMemberNav'
import { useI18n } from 'vue-i18n'

const router = useRouter()
const $t = useI18n().t
const sessionStore = stores.useSessionStore()
const isProfileDialogOpen = ref(false),
  group = ref(null)
const { selectedRoute: selectedNav } = useSelectedMemberNav()

// PROPS
const props = defineProps({
  isDrawerOpen: {
    type: Boolean
  }
})

// EMITS
const emit = defineEmits(['update:isDrawerOpen'])

// WATCHERS
watch(group, () => {
  isProfileDialogOpen.value = false
})

// COMPUTED
const appVersion = computed(() => stores.useAppStore().getVersion)

const drawerOpen = computed({
  get() {
    return props.isDrawerOpen
  },
  set(value) {
    emit('update:isDrawerOpen', value)
  }
})

// METHODS
const logout = async () => {
  await sessionStore.logout()
  stores.useToastStore().showSuccessToast($t('navigation.logoutMessage'))
  await router.push({ name: `member.login` })
}

const openProfileEditDialog = () => {
  isProfileDialogOpen.value = true
}

const navigate = (to) => {
  drawerOpen.value = false
  router.push(to)
}
</script>

<style scoped lang="scss">
.logout {
  cursor: pointer;
}

.app-version {
  font-size: 0.7rem;
  padding-top: 1rem;
  margin-left: 1.5rem;
}
</style>
