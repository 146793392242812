export default {
  getTransactions(state) {
    return state.transactions
  },
  getStatementTransactions(state) {
    return state.statementTransactions
  },
  getStatementTransactionsWithFeesApplied(state) {
    return state.statementTransactionsWithFeesApplied
  },
  getGridTransactions(state) {
    return state.gridTransactions
  },
  getTransactionInsightsTotalGifts(state) {
    return state.transactionInsightsTotalGifts
  },
  getRecurringTransactions(state) {
    return state.recurringTransactions
  },
  getDeposits(state) {
    return state.deposits
  }
}
