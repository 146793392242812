import GivingProxy from '@/proxy/GivingProxy'
import { toRaw } from 'vue'
import { v4 as createUUID } from 'uuid'
import stores from '@/stores'
import sentryErrorLoggingHelper from '@/helpers/sentryErrorLoggingHelper'

export default {
  /**
   * get giving forms
   * @param {object} payload
   * @param {boolean} formExists
   * @returns {Promise<{success: boolean, error: null}>}
   */
  async fetchGivingForm(payload, formExists) {
    const result = {
      success: false,
      error: null
    }
    const proxy = new GivingProxy()

    try {
      const response = formExists
        ? await proxy.getGivingFormByFormId(payload)
        : await proxy.getGivingFormByWebId(payload.webId)
      if (response?.data?.formData) {
        this.activeForm = response.data
        result.success = true
      } else if (!response.data.success && response.data.message === 'Form not found') {
        this.activeForm = structuredClone(toRaw(this.getDefaultCardConfig))
        this.activeForm.formData.accounts = response.data.accounts
      }
    } catch (error) {
      result.error = error
      if (error?.status && error.status === 404) {
        this.router.push({ name: 'NotFound' })
      } else {
        sentryErrorLoggingHelper().sentryErrorWithContext(error)
      }
    }

    return result
  },

  /**
   * update a giving form
   *
   * @returns {Promise<void>}
   */
  async updateMerchantGivingForm() {
    const result = {
      success: false,
      error: null
    }
    const proxy = new GivingProxy()
    let response = null

    const form = {
      ...this.activeForm
    }

    try {
      response = await proxy.updateGivingForm(form)

      if (response) {
        this.activeForm = response.data
        await this.setFormState()
        result.success = true
      } else {
        result.error = true
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      result.error = error
    }

    return result
  },

  /**
   * update a giving form
   *
   * @returns {Promise<void>}
   */
  async createMerchantGivingForm() {
    const result = {
      success: false,
      error: null
    }
    const proxy = new GivingProxy()
    let response = null

    const form = {
      ...this.activeForm,
      merchantId: stores.useOrganizationStore().getMerchantData.merchantId,
      urlToken: createUUID(),
      active: true
    }

    try {
      response = await proxy.createGivingForm(form)

      if (response) {
        this.activeForm = response.data
        await this.setFormState()
        result.success = true
      } else {
        result.error = true
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      result.error = error
    }

    return result
  },

  async applyUpdatesToForm() {
    this.activeForm.formData.accounts = this.accounts
    this.activeForm.formData.chips = this.amountChips
    this.activeForm.formData.customizationOptions.bodyFontColor = this.bodyFontColor
    this.activeForm.formData.customizationOptions.buttonBgColor = this.buttonColor
    this.activeForm.formData.customizationOptions.buttonTextColor = this.buttonTextColor
    this.activeForm.formData.customizationOptions.cardBackground = this.cardBackground
    this.activeForm.formData.customizationOptions.headerFontColor = this.headerColor
    this.activeForm.formData.customizationOptions.img = this.image
    this.activeForm.formData.customizationOptions.subheaderFontColor = this.subheaderColor
    this.activeForm.formData.header = this.header
    this.activeForm.formData.subtitle = this.subtitle
    this.activeForm.formData.text = this.bodyText

    if (this.activeForm.id) {
      return await this.updateMerchantGivingForm()
    } else {
      return await this.createMerchantGivingForm()
    }
  },

  /**
   * get the current active form for the web id
   *
   *  @param webId
   * @returns {Promise<void>}
   */
  async getActiveGivingForm(payload, formExists) {
    await this.fetchGivingForm(payload, formExists)

    await this.setFormState()
  },

  /**
   * set the state from the active form config
   *
   * @returns {Promise<void>}
   */
  async setFormState() {
    if (this.activeForm?.formData) {
      this.accounts = this.activeForm.formData?.accounts
      this.amountChips = this.filteredAndSortedAmountChips(this.activeForm.formData.chips)
      this.bodyFontColor = this.activeForm.formData.customizationOptions.bodyFontColor
      this.buttonColor = this.activeForm.formData.customizationOptions.buttonBgColor
      this.buttonTextColor = this.activeForm.formData.customizationOptions.buttonTextColor
      this.cardBackground = this.activeForm.formData.customizationOptions.cardBackground
      this.headerColor = this.activeForm.formData.customizationOptions.headerFontColor
      this.image = this.activeForm.formData.customizationOptions.img
      this.subheaderColor = this.activeForm.formData.customizationOptions.subheaderFontColor
      this.header = this.activeForm.formData.header
      this.subtitle = this.activeForm.formData.subtitle
      this.bodyText = this.activeForm.formData.text

      // where we will store the giving totals for each account
      this.createOrUpdateGiftAccounts()
    }
  },

  /**
   * set the state from the active form config
   *
   * @returns Object
   */
  createOrUpdateGiftAccounts() {
    // where we will store the giving totals for each account
    this.accounts.forEach((account) => {
      if (!this.giftAccounts[account.acct_id]) {
        this.giftAccounts[account.acct_id] = {
          name: account.acct_name,
          total: Number(0),
          tax_ind: account.tax_ind
        }
      }
    })

    // remove accounts from giftAccounts that do not exist in accounts
    for (let key in this.giftAccounts) {
      let keyFound = false
      this.accounts.forEach((account) => {
        if (account.acct_id === key) {
          keyFound = true
        }
      })

      if (!keyFound) {
        delete this.giftAccounts[key]
      }
    }

    return { success: true }
  },

  /**
   * Resets the gift account so that it does not persist old form values
   * @this givingStore
   */
  async clearGiftAccounts() {
    this.giftAccounts = {}
  },

  /**
   * Resets the mobile giving gift account so that it does not persist old form values
   * @this givingStore
   */
  clearMobileGivingGiftAccounts() {
    this.textToGiveGiftAccounts = []
  },

  filteredAndSortedAmountChips(chips) {
    return chips
      .filter((amount) => {
        return this.amountChipValues.includes(amount)
      })
      .sort()
  },

  async uploadGivingImage(files, bucket, hasACL, isPublic = false) {
    const formData = new FormData()
    const isArray = Array.isArray(files)

    for (const file of files) {
      formData.append('files', file)
    }

    const proxy = new GivingProxy()
    return await proxy.uploadImageToS3(
      formData,
      stores.useOrganizationStore().getWebId,
      bucket,
      isArray && files.length > 1 ? 'many' : 'one',
      hasACL,
      isPublic
    )
  }
}
