import { useToast } from 'vue-toastification'
import EmailNotVerifiedToast from '@/components/members/login/EmailNotVerifiedToast.vue'
import PaymentMethodExpiredToast from '@/components/giving/PaymentMethodExpiredToast.vue'

const toast = useToast()
export default {
  showSuccessToast(message, config = {}) {
    return toast.success(message, {
      timeout: 5000,
      ...config
    })
  },
  showErrorToast(message, config = {}) {
    return toast.error(message, {
      closeOnClick: true,
      ...config
    })
  },
  showWarningToast(message, config = {}) {
    return toast.warning(message, {
      timeout: 10000,
      ...config
    })
  },
  showInfoToast(message, config = {}) {
    return toast.info(message, {
      closeOnClick: true,
      ...config
    })
  },
  showEmailNotVerifiedToast() {
    const content = {
      component: EmailNotVerifiedToast
    }
    toast(content, { toastClassName: 'component-toast' })
  },
  paymentMethodExpiredVerifiedToast() {
    const content = {
      component: PaymentMethodExpiredToast
    }
    toast(content, { toastClassName: 'component-toast' })
  }
}
