<template>
  <div class="profile-section-container d-none d-lg-flex">
    <v-row justify="center">
      <v-menu min-width="200px" content-class="profile-overlay" rounded>
        <template #activator="{ props }">
          <v-btn icon v-bind="props">
            <v-avatar color="primary" size="large">
              <v-icon v-if="!authenticated" icon="mdi-account-circle"></v-icon>
              <span class="text-h6">{{ getInitials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <div class="mx-auto text-center">
              <v-avatar color="primary">
                <span class="text-h6">{{ getInitials }}</span>
              </v-avatar>
              <p class="mt-1">{{ getFullName }}</p>
              <p class="text-caption mt-1">
                {{ getEmail }}
              </p>
              <v-divider class="my-3"></v-divider>
              <ThemeToggle />
              <v-divider class="my-3"></v-divider>
              <v-btn variant="text" @click="openProfileEditDialog">
                {{ $t('userDetails.editProfile') }}
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn variant="text" @click="logout">
                {{ $t('navigation.logout') }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-row>
    <ProfileEditDialog v-model:is-profile-dialog-open="isProfileDialogOpen" />
  </div>
</template>
<script setup>
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import stores from '@/stores'
import ThemeToggle from '@/components/theme/ThemeToggle.vue'
import ProfileEditDialog from '@/components/profile-edit-dialog/ProfileEditDialog.vue'

const $t = useI18n().t
const router = useRouter()
const authenticated = stores.useSessionStore().isAuthenticated
const isProfileDialogOpen = ref(false),
  group = ref(null)

// WATCHERS
watch(group, () => {
  isProfileDialogOpen.value = false
})

// COMPUTED
const getInitials = computed(() => {
  return authenticated ? stores.useUserStore().getInitials : ''
})

const getFullName = computed(() => {
  return authenticated ? stores.useUserStore().getFullName : ''
})

const getEmail = computed(() => {
  return authenticated ? stores.useUserStore().getEmail : ''
})

// METHODS
const openProfileEditDialog = () => {
  isProfileDialogOpen.value = true
}

const logout = async () => {
  // The stores are destroyed on logout, so we need to determine if the user is a member or not before we logout
  const logoutRoute = stores.useSessionStore().getMemberId ? 'member.login' : 'login.index'
  await stores.useSessionStore().logout()
  stores.useToastStore().showSuccessToast($t('navigation.logoutMessage'))
  await router.push({ name: logoutRoute })
}
</script>

<style scoped lang="scss">
@import '@/styles/_colors.scss';

.profile-section-container {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 1.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
<style lang="scss">
.profile-overlay {
  position: absolute !important;
}
</style>
