const snakeCase = (str) => {
  return str
    .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
    .trim() // Trim leading and trailing spaces
    .replace(/([a-z0-9])([A-Z])/g, '$1_$2') // Add underscore between a lowercase letter or number and an uppercase letter
    .replace(/([A-Z]+)([A-Z][a-z0-9])/g, '$1_$2') // Add underscore between consecutive uppercase letters followed by a lowercase letter or number
    .toLowerCase() // Convert the entire string to lowercase
    .replace(/[\s]+/g, '_') // Replace spaces with underscores
    .replace(/[^\w]+/g, '') // Remove all non-word characters (optional, based on use case)
    .replace(/_+/g, '_') // Replace multiple underscores with a single underscore
    .replace(/^_/, '') // Remove leading underscore if any
    .replace(/_$/, '') // Remove trailing underscore if any
}

const formatUsPhone = (phone) => {
  const cleaned = ('' + phone).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}
const normalizeUSPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return phoneNumber
  }
  // Remove all non-digit characters from the string
  let digits = phoneNumber.replace(/\D/g, '')

  // Check if the number starts with the US country code (1) and remove it
  if (digits.length === 11 && digits.startsWith('1')) {
    digits = digits.substring(1)
  }

  // Ensure the resulting string is exactly 10 digits
  if (digits.length !== 10) {
    console.log('Invalid US phone number length')
    //throw new Error('Invalid US phone number length')
  }

  return digits
}

export { formatUsPhone, normalizeUSPhoneNumber, snakeCase }
