/*
  This file contains helper functions for working with objects.
*/

export default {
  /**
   * Compare two objects to see if they are equal.
   * @param {Object} o1
   * @param {Object} o2
   * @returns boolean
   * @example
   * const o1 = { a: 1, b: 2 }
   * const o2 = { a: 1, b: 2 }
   */

  compareObjects(o1, o2) {
    const keys1 = Object.keys(o1)
    const keys2 = Object.keys(o2)
    if (keys1.length !== keys2.length) {
      return false
    }
    for (let key of keys1) {
      if (o1[key] !== o2[key]) {
        return false
      }
    }
    return true
  }
}
