import Proxy from './Proxy'

export default class SessionProxy extends Proxy {
  /**
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super(`${import.meta.env.VITE_ACCOUNT_API_BASE_URL}/v1`, parameters)
  }

  /**
   * * Method used for login.
   *
   * @param id
   * @param email
   * @param password
   * @returns {Promise}
   */
  async staffLogin({ id, email, password }) {
    return this.submit('post', `${this.endpoint}/login/${id}`, { email, password })
  }

  /**
   * * Method used for member login.
   *
   * @param id
   * @param email
   * @param password
   * @returns {Promise}
   */
  async memberLogin({ id, email, password }) {
    return this.submit('post', `${this.endpoint}/login/ind/${id}`, { email, password })
  }

  /**
   * * Method used for member login via sms.
   * @param webId
   * @param giverId
   * @param phoneNumber\
   * @returns {Promise}
   */
  async smsMemberLogin({ webId, giverId, phoneNumber }) {
    return this.submit('post', `${this.endpoint}/login/sms/${webId}/${giverId}`, { phoneNumber })
  }

  /**
   * Forgot Password
   * @returns {Promise}
   */
  // TODO: Not Used?
  async forgotPassword({ email, id }) {
    return this.submit('post', `${this.endpoint}/forgot/${id}`, { email })
  }

  /**
   * Forgot Individual Password
   * @returns {Promise}
   */
  // TODO: Not Used?
  async forgotMemberPassword({ email, id }) {
    return this.submit('post', `${this.endpoint}/forgot/ind/${id}`, { email })
  }

  /**
   * Check if member password is valid before change
   * @returns {Promise}
   * @param id
   * @param email
   * @param password
   */
  async checkMemberPassword({ id, email, password }) {
    return this.submit('post', `${this.endpoint}/forgot/check_member/${id}`, { email, password })
  }

  /**
   * Check if staff password is valid before change
   * @returns {Promise}
   * @param id
   * @param email
   * @param password
   */
  async checkStaffPassword({ id, email, password }) {
    return this.submit('post', `${this.endpoint}/forgot/check_staff/${id}`, { email, password })
  }

  /**
   * Hash Password before change
   * @returns {Promise}
   * @param id
   * @param password
   */
  async hashPassword({ id, password }) {
    return this.submit('post', `${this.endpoint}/forgot/hash/${id}`, { password })
  }
}
