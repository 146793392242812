import useRruleHelpers from '@/helpers/rrule'

export default () => {
  const { newRecurrencePattern, startDate } = useRruleHelpers()
  const pattern = newRecurrencePattern()

  return {
    checkOutData: {
      accounts: [],
      feeAmount: 0,
      giftNote: '',
      paymentInfo: null,
      saveAsDefaultPaymentInfo: false,
      savedGiftAsScheduledGift: false,
      userPaysFees: false
    },
    checkOutRecurringGift: {
      recurrencePattern: pattern,
      active: false,
      start: startDate(pattern).toDate(),
      // NOT USED
      end: null
    },
    receiptData: {}
  }
}
