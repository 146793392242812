<template>
  <teleport to="body">
    <v-dialog v-model="profileDialogOpen" :persistent="true" class="profile-edit-dialog" :scrollable="true">
      <div v-if="!loading" class="profile-edit-container">
        <v-card class="card-form">
          <form data-test-id="profile-edit-dialog-form" @submit="onSubmit">
            <v-card-title>
              <div class="text-h5 pt-4">{{ $t('userSettings.editProfile') }}</div>
            </v-card-title>
            <v-card-text v-if="formError">
              <v-alert
                type="error"
                border="left"
                elevation="1"
                colored-border
                dismissible
                @click:close="formError = false"
              >
                {{ $t('userSettings.formError') }}
              </v-alert>
            </v-card-text>
            <v-container>
              <v-row class="align-center" :dense="true">
                <v-col cols="12">
                  <span
                    v-if="!isUserMember"
                    class="text-subtitle-1"
                    data-test-id="profile-edit-dialog-portalemail-text"
                    >{{ currentUserData.portalemail }}</span
                  >
                  <Field
                    v-if="isUserMember"
                    v-slot="{ field, errors }"
                    v-model="currentUserData.portalemail"
                    name="portalemail"
                  >
                    <v-text-field
                      v-bind="field"
                      :model-value="currentUserData.portalemail"
                      :label="$t('signup.email')"
                      :hint="$t('signup.emailHint')"
                      density="compact"
                      :error-messages="errors"
                      autocomplete="username"
                      variant="outlined"
                      type="email"
                      data-test-id="profile-edit-dialog-portalemail-text-field"
                    />
                  </Field>
                </v-col>
              </v-row>
              <v-row class="align-center pb-2" :dense="true">
                <v-col>
                  <v-btn variant="text" size="medium" class="pa-0 ma-0" color="button" @click="resetUserPassword">
                    {{ $t('commonFormLabels.changePass') }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row :dense="true">
                <v-col cols="12" md="6">
                  <Field v-slot="{ field, errors }" v-model="currentUserData.first_name" name="firstname">
                    <v-text-field
                      :model-value="currentUserData.first_name"
                      v-bind="field"
                      density="compact"
                      type="text"
                      variant="outlined"
                      :label="$t('signup.firstname')"
                      :error-messages="errors"
                      :dense="true"
                      maxlength="30"
                    />
                  </Field>
                </v-col>
                <v-col cols="12" md="6">
                  <Field v-slot="{ field, errors }" v-model="currentUserData.last_name" name="lastname">
                    <v-text-field
                      :model-value="currentUserData.last_name"
                      v-bind="field"
                      :label="$t('signup.lastname')"
                      density="compact"
                      type="text"
                      :error-messages="errors"
                      variant="outlined"
                      maxlength="50"
                    />
                  </Field>
                </v-col>
              </v-row>
              <v-row :dense="true">
                <v-col>
                  <Field v-if="isUserMember" v-slot="{ field, errors }" v-model="currentUserData.addr1" name="address1">
                    <v-text-field
                      :model-value="currentUserData.addr1"
                      v-bind="field"
                      :label="$t('signup.address')"
                      density="compact"
                      type="text"
                      :error-messages="errors"
                      variant="outlined"
                      maxlength="120"
                    />
                  </Field>
                </v-col>
              </v-row>
              <v-row :dense="true">
                <v-col cols="12" md="4">
                  <Field v-if="isUserMember" v-slot="{ field, errors }" v-model="currentUserData.city" name="city">
                    <v-text-field
                      :model-value="currentUserData.city"
                      v-bind="field"
                      :label="$t('signup.city')"
                      density="compact"
                      type="text"
                      :error-messages="errors"
                      variant="outlined"
                      maxlength="25"
                    />
                  </Field>
                </v-col>
                <v-col cols="12" md="4">
                  <Field v-if="isUserMember" v-slot="{ field, errors }" v-model="currentUserData.state" name="state">
                    <v-select
                      :model-value="currentUserData.state"
                      v-bind="field"
                      :items="states"
                      item-value="descs"
                      item-title="descs"
                      :label="$t('signup.state')"
                      required
                      density="compact"
                      type="text"
                      :error-messages="errors"
                      variant="outlined"
                      maxlength="2"
                    />
                  </Field>
                </v-col>
                <v-col cols="12" md="4" :dense="true">
                  <Field v-if="isUserMember" v-slot="{ field, errors }" v-model="currentUserData.zip" name="zip">
                    <v-text-field
                      :model-value="currentUserData.zip"
                      v-bind="field"
                      :label="$t('signup.zip')"
                      density="compact"
                      type="text"
                      :error-messages="errors"
                      variant="outlined"
                      maxlength="10"
                    />
                  </Field>
                </v-col>
              </v-row>
              <v-row :dense="true">
                <v-col cols="12">
                  <Field
                    v-if="isUserMember"
                    v-slot="{ field, errors }"
                    v-model="currentUserData.c_phone"
                    name="cellphone"
                  >
                    <v-text-field
                      v-bind="field"
                      :model-value="currentUserData.c_phone"
                      :label="$t('signup.cellphone')"
                      density="compact"
                      :error-messages="errors"
                      variant="outlined"
                      type="tel"
                    />
                  </Field>
                </v-col>
              </v-row>
              <div v-if="isUserMember && currentGiverSettings">
                <v-row :dense="true">
                  <v-col cols="10">
                    <v-checkbox
                      v-model="currentGiverSettings.coverFees"
                      :true-value="true"
                      :false-value="false"
                      hide-details
                      density="compact"
                      :label="$t('giving.textToGive.optionsCard.feesCheckboxMainLabelOneTime')"
                      data-test-id="profile-edit-dialog-cover-fees-checkbox"
                    />
                  </v-col>
                  <v-col cols="2" class="mt-1">
                    <v-icon color="rgb(10, 122, 0)">help</v-icon>
                    <v-tooltip activator="parent" location="top">
                      {{ $t('giving.textToGive.optionsCard.feeInformation') }}
                    </v-tooltip>
                  </v-col>
                </v-row>
              </div>
              <v-card-actions>
                <v-row :dense="true">
                  <v-spacer />
                  <v-btn width="120" color="button" variant="outlined" class="pr-4" @click="closeProfileDialog">
                    {{ $t('common.cancel') }}
                  </v-btn>

                  <v-btn
                    width="120"
                    color="button"
                    variant="flat"
                    type="submit"
                    :disabled="!formMeta.valid || !isFormDirty || pending"
                    data-test-id="profile-edit-dialog-save-btn"
                  >
                    {{ $t('common.save') }}
                  </v-btn>
                </v-row>
              </v-card-actions>
            </v-container>
          </form>
        </v-card>
      </div>
    </v-dialog>
    <ForgotPassword
      v-if="currentUserData"
      v-model:is-dialog-open="isPasswordResetDialogOpen"
      :dialog-user-type="passwordDialogType"
      :initial-email="currentUserData.portalemail"
      :email-read-only="emailReadOnly"
      :title="$t('forgotPassword.sendPasswordResetMember')"
      :message="$t('forgotPassword.enterYourEmailResetPassword')"
      :web-id="webId"
    />
  </teleport>
</template>

<script setup>
import { computed, onBeforeMount, ref, watch } from 'vue'
import * as yup from 'yup'
import 'yup-phone-lite'
import { Field, useForm } from 'vee-validate'
import { useI18n } from 'vue-i18n'
import stores from '@/stores'
import ForgotPassword from '@/components/password/ForgotPassword.vue'
import { storeToRefs } from 'pinia'
import { toRaw } from 'vue'

const $t = useI18n().t

const userStore = stores.useUserStore()
const sessionStore = stores.useSessionStore()
const webId = stores.useOrganizationStore().getWebId

const currentGiverRecordData = ref(null),
  currentGiverSettings = ref(null),
  currentUserData = ref(null),
  emailReadOnly = ref(true),
  formError = ref(false),
  group = ref(null),
  isPasswordDialogOpen = ref(false),
  isPasswordResetDialogOpen = ref(false),
  isUserMember = ref(false),
  loading = ref(false),
  pending = ref(false),
  { getUserProfileStates: states } = storeToRefs(userStore)

// PROPS
const props = defineProps({
  isProfileDialogOpen: {
    type: Boolean
  }
})

// VEE VALIDATE NEEDS
const memberValidationSchema = yup.object({
  address1: yup.string().notRequired().max(120, $t('validations.memberProfile.address.length')),
  cellphone: yup.string().phone(['US', 'CA'], $t('validations.memberProfile.cellphone.valid')),
  city: yup
    .string()
    .required($t('validations.memberProfile.city.required'))
    .max(40, $t('validations.memberProfile.city.length')),
  firstname: yup
    .string()
    .required($t('validations.memberProfile.firstname.required'))
    .max(30, $t('validations.memberProfile.firstname.length')),
  lastname: yup
    .string()
    .required($t('validations.memberProfile.lastname.required'))
    .max(50, $t('validations.memberProfile.lastname.length')),
  portalemail: yup
    .string()
    .required($t('validations.memberProfile.email.required'))
    .email($t('validations.memberProfile.email.valid'))
    .max(180, $t('validations.memberProfile.email.length')),
  state: yup
    .string()
    .required($t('validations.memberProfile.state.required'))
    .max(2, $t('validations.memberProfile.state.length')),
  zip: yup
    .string()
    .required($t('validations.memberProfile.zip.required'))
    .max(10, $t('validations.memberProfile.zip.length'))
})

const staffValidationSchema = yup.object({
  firstname: yup
    .string()
    .required($t('validations.staffProfile.firstName.required'))
    .max(16, $t('validations.staffProfile.firstName.length')),
  lastname: yup
    .string()
    .required($t('validations.staffProfile.lastName.required'))
    .max(18, $t('validations.staffProfile.lastName.length'))
})

const validationSchema = computed(() => {
  return isUserMember.value ? memberValidationSchema : staffValidationSchema
})

// Needs initialized here after validationSchema(s) is computed
const { handleSubmit, meta: formMeta } = useForm({ validationSchema })

// EMITS
const emit = defineEmits(['update:isProfileDialogOpen'])

// WATCHERS
watch(group, () => {
  isPasswordDialogOpen.value = false
})

// COMPUTED
const isFormDirty = computed(() => {
  if (isUserMember.value) {
    return (
      formMeta.value.dirty ||
      userStore.getGiverRecordData?.giversettings?.coverFees !== currentGiverSettings.value?.coverFees
    )
  }
  return formMeta.value.dirty
})

const passwordDialogType = computed(() => {
  return isUserMember.value ? 'member' : 'staff'
})

const profileDialogOpen = computed({
  get() {
    return props.isProfileDialogOpen
  },
  set(value) {
    emit('update:isProfileDialogOpen', value)
  }
})

/**
 * @description - This function resets the data used by profile edit form to the original values.
 * @returns {void}
 */
const resetUser = () => {
  currentUserData.value = structuredClone(toRaw(userStore.profileData))
  currentGiverRecordData.value = structuredClone(toRaw(userStore.giverRecordData))
  currentGiverSettings.value = structuredClone(toRaw(userStore.giverRecordData?.giversettings))
}

// METHODS

/**
 * @description - This function checks to see if the user's email entered is already in use for their organization.
 * @returns {boolean} - Returns true if the email is already in use, and false if it is not.
 */
const checkEmailInUse = async () => {
  const payload = {
    webid: webId,
    email: currentUserData.value.portalemail
  }
  const result = await userStore.checkProfile(payload)
  return !!result?.emailFound
}

const closeProfileDialog = () => {
  resetUser()
  profileDialogOpen.value = false
}

const handleProfileSavedNotification = (response) => {
  if (response?.success) {
    stores.useToastStore().showSuccessToast($t('userSettings.profileSaved'))
  } else {
    stores.useToastStore().showErrorToast($t('userSettings.profileNotSaved'))
  }
}

/**
 * @description - This function is called when the user clicks the save button on the profile edit dialog.
 * It checks to see if the user has changed their email address, and if so, checks to see if the new email address is already in use.
 * If the email address is already in use, it displays a toast message and does not save the profile and does not close the profile dialog.
 * If the email address is not already in use, it saves the profile and closes the profile dialog.
 * If the email address has not changed, it saves the profile and closes the profile dialog.
 * @returns {void}
 */
const onSubmit = handleSubmit(async () => {
  pending.value = true
  let response
  let emailChanged = false
  let emailInUse = false
  if (isUserMember.value) {
    emailChanged = currentUserData.value.portalemail !== userStore.getProfileData.portalemail
    emailInUse = await checkEmailInUse()
    if (emailChanged && emailInUse) {
      stores.useToastStore().showInfoToast($t('userSettings.emailInUse', { email: currentUserData.value.portalemail }))
    }

    if (!emailChanged || !emailInUse) {
      currentGiverRecordData.value.giversettings = currentGiverSettings.value
      await userStore.updateGiverRecord(currentGiverRecordData.value)
      response = await userStore.updateMemberProfile(currentUserData.value)
    }
  } else {
    response = await userStore.updateStaffProfile(currentUserData.value)
  }
  pending.value = false
  if (!emailChanged || !emailInUse) {
    handleProfileSavedNotification(response)
    closeProfileDialog()
  }
})

const resetUserPassword = () => {
  isPasswordResetDialogOpen.value = true
}

//LIFECYCLE
onBeforeMount(async () => {
  if (sessionStore.getMemberId) {
    isUserMember.value = true
  }
  if (!states.value.length) {
    await userStore.getProfileRefStates(webId)
  }
  resetUser()
})
</script>

<style lang="scss" scoped>
@import '@/styles/index.scss';

.profile-edit-dialog {
  :deep(.v-overlay__content) {
    justify-content: center;
    align-items: center;
    padding: 0;
    max-height: calc(100% - 16px);
  }
}

.profile-edit-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 550px;

  .card-form {
    padding: 1rem;
    min-width: 350px;

    @media screen and (min-width: 1000px) {
      min-width: 550px;
    }
  }
}
</style>
