import useAccountsStore from '@/stores/accounts'
import useAppStore from '@/stores/app'
import useGiverStore from '@/stores/giver'
import useGivingStore from '@/stores/givingcard'
import useOrganizationStore from '@/stores/organization'
import useSessionStore from '@/stores/session'
import useTransactionStore from '@/stores/transactions'
import useUnsplashStore from '@/stores/unsplash'
import useUserStore from '@/stores/user'
import useRainforestStore from '@/stores/rainforest'
import useRecaptchaStore from '@/stores/recaptcha'
import useRecurringGiftsStore from '@/stores/recurringGifts'
import useCheckOutStore from '@/stores/checkOut'
import useStaffStore from '@/stores/staff'
import useLogoutTimerStore from '@/stores/logoutTimer'
import useToastStore from '@/stores/toast'

export default {
  useAccountsStore,
  useAppStore,
  useCheckOutStore,
  useGiverStore,
  useGivingStore,
  useLogoutTimerStore,
  useOrganizationStore,
  useRainforestStore,
  useRecaptchaStore,
  useRecurringGiftsStore,
  useSessionStore,
  useStaffStore, // ORGANIZATION STAFF MEMBERS
  useToastStore,
  useTransactionStore,
  useUnsplashStore,
  useUserStore
}
