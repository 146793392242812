import Proxy from './Proxy'

export default class RainforestProxy extends Proxy {
  /**
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super(`${import.meta.env.VITE_GIVING_API_BASE_URL}/v1`, parameters)
  }

  async createPaymentMethodConfig(payload) {
    return this.submit('post', `${this.endpoint}/payments/createconfig/`, payload)
  }

  async createPaymentMethodSession(payload) {
    return this.submit('post', `${this.endpoint}/payments/createsession/`, payload)
  }

  async savePaymentMethod(payload) {
    return this.submit('post', `${this.endpoint}/payments/givertoken`, payload)
  }
}
