<template>
  <div class="switch-container">
    <v-switch
      v-model="$vuetify.theme.current.dark"
      density="compact"
      hide-details
      :label="theme.current.value.dark ? $t('components.themeToggle.dark') : $t('components.themeToggle.light')"
      @click="toggleTheme"
    />
  </div>
</template>

<script setup>
import { useTheme } from 'vuetify'

const theme = useTheme()
const toggleTheme = () => {
  theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
  localStorage.setItem('theme_variant', theme.global.current.value.dark ? 'dark' : 'light')
}
</script>

<style lang="scss">
.switch-container {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}
</style>
