import { useRoute } from 'vue-router'
import { onMounted, ref, watch } from 'vue'

export const useSelectedMemberNav = () => {
  const route = useRoute()
  const selectedRoute = ref('')

  // WATCHERS
  watch(route, () => {
    updateSelectedNav()
  })

  const updateSelectedNav = () => {
    switch (route.name) {
      case 'member.scheduled':
        selectedRoute.value = 'scheduled'
        break
      case 'member.paymentmethods':
        selectedRoute.value = 'paymentmethods'
        break
      case 'giving':
      case 'billing':
      case 'checkout':
      case 'thanks':
        selectedRoute.value = 'giving'
        break
      case 'settings':
        selectedRoute.value = 'settings'
        break
      default:
        selectedRoute.value = 'home'
    }
  }

  onMounted(() => {
    updateSelectedNav()
  })

  return { selectedRoute }
}
