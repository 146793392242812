<template>
  <router-view v-slot="{ Component, route }">
    <MemberBasicLayout v-if="!authenticated">
      <transition name="fade" mode="out-in">
        <Component :is="Component" :key="route.name" />
      </transition>
    </MemberBasicLayout>
    <MemberAppLayout v-if="authenticated">
      <transition name="fade" mode="out-in">
        <Component :is="Component" :key="route.name" />
      </transition>
    </MemberAppLayout>
  </router-view>
</template>

<script setup>
import stores from '@/stores'
import MemberBasicLayout from '@/layout/MemberBasicLayout.vue'
import MemberAppLayout from '@/layout/MemberAppLayout.vue'
const authenticated = stores.useSessionStore().isAuthenticated
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
