import GivingTransitionRouter from '@/views/giving/GivingTransitionRouter.vue'
import MemberAccountTransitionRouter from '@/views/member/account/MemberAccountTransitionRouter.vue'
import MemberTransitionRouter from '@/views/member/MemberTransitionRouter.vue'
import StaffTransitionRouter from '@/views/staff/StaffTransitionRouter.vue'
import stores from '@/stores'
import { h, resolveComponent } from 'vue'
import SkMobileGivingTransitionRouter from '@/views/giving/SkMobileGivingTransitionRouter.vue'

export default [
  {
    path: '/',
    name: 'homepage',
    component: () => import('@/views/HomePageView.vue')
  },
  {
    path: '/findchurch',
    name: 'findchurch',
    component: () => import('@/views/find-church/FindChurchView.vue')
  },
  {
    path: '/:webId/',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    meta: {
      authRequired: false
    },
    beforeEnter: async (to, from, next) => {
      const webId = to.params?.webId

      if (webId) {
        next()
      } else {
        return next(false)
      }
    },
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/HomeView.vue'),
        meta: {
          authRequired: false,
          isMemberPage: true
        }
      },
      {
        path: 'member/',
        component: {
          render() {
            return h(MemberTransitionRouter)
          }
        },
        meta: {
          authRequired: true,
          isMemberPage: true
        },
        beforeEnter: async (to, from, next) => {
          const memberId = stores.useSessionStore().getMemberId
          const isAuthenticated = stores.useSessionStore().isAuthenticated
          const webId = to.params.webId
          if (!memberId && isAuthenticated) {
            next({ name: 'dashboard.index', params: { webId } })
          } else if (!memberId && !isAuthenticated) {
            next({ name: 'home', params: { webId } })
          } else if (isAuthenticated && to?.query?.redirect) {
            next({ path: to.query.redirect })
          } else {
            next()
          }
        },
        children: [
          {
            path: '',
            name: 'member.index',
            component: () => import('@/components/members/transactions/TransactionsList.vue')
          },
          {
            path: 'transactions',
            name: 'member.transactions',
            component: () => import('@/components/members/transactions/TransactionsList.vue')
          },
          {
            path: 'transaction/:cont_id',
            name: 'member.transaction',
            component: () => import('@/components/members/transactions/TransactionDetails.vue'),
            props: true
          },
          {
            path: 'scheduled',
            name: 'member.scheduled',
            component: () => import('@/components/members/scheduledGifts/ScheduledGiftsList.vue')
          },
          {
            path: 'paymentmethods',
            name: 'member.paymentmethods',
            component: () => import('@/components/members/paymentMethods/PaymentMethodsList.vue')
          }
        ]
      },
      {
        path: 'member/login',
        component: {
          render() {
            return h(MemberAccountTransitionRouter)
          }
        },
        beforeEnter: async (to, from, next) => {
          const sessionStore = stores.useSessionStore()
          const memberId = sessionStore.getMemberId
          const isAuthenticated = sessionStore.isAuthenticated
          const webId = to.params.webId
          if (!memberId && isAuthenticated) {
            next({ name: 'dashboard.index', params: { webId } })
          } else if (memberId && isAuthenticated) {
            next({ name: 'member.index', params: { webId } })
          } else {
            next()
          }
        },
        meta: {
          authRequired: false,
          isMemberPage: true
        },
        children: [
          {
            path: '',
            name: 'member.login',
            component: () => import('@/components/members/login/LoginForm.vue'),
            meta: {
              authRequired: false,
              isMemberPage: true
            }
          },
          {
            path: 'forgot',
            name: 'member.forgot',
            component: () => import('@/components/members/login/LoginForm.vue'),
            meta: {
              authRequired: false,
              isMemberPage: true
            }
          },
          {
            path: 'signup',
            name: 'member.signup',
            component: () => import('@/components/members/login/SignupStep1.vue'),
            meta: {
              authRequired: false,
              isMemberPage: true
            }
          },
          {
            path: 'create-account',
            name: 'member.signup.new',
            component: () => import('@/components/members/login/SignupStep2.vue'),
            meta: {
              authRequired: false,
              isMemberPage: true
            }
          }
        ]
      },
      {
        path: 'staff/',
        component: {
          render() {
            return h(StaffTransitionRouter)
          }
        },
        meta: {
          authRequired: true,
          isMemberPage: false
        },
        beforeEnter: async (to, from, next) => {
          const memberId = stores.useSessionStore().getMemberId
          const isAuthenticated = stores.useSessionStore().isAuthenticated
          const webId = to.params.webId
          if (memberId) {
            next({ name: 'member.index', params: { webId } })
          } else if (isAuthenticated && to?.query?.redirect) {
            next({ path: to.query.redirect })
          } else {
            next()
          }
        },
        children: [
          {
            path: '',
            name: 'dashboard.index',
            component: () => import('@/components/staff/dashboard/SkDashboard.vue')
          },
          {
            path: 'deposits',
            name: 'deposits.index',
            component: () => import('@/components/staff/deposits/DepositsList.vue')
          },
          {
            path: 'deposit/:batch',
            name: 'deposit.batch',
            component: () => import('@/components/staff/deposits/DepositDetails.vue')
            // props: true
          },
          {
            path: 'accounts',
            name: 'accounts.index',
            component: () => import('@/components/staff/accounts/AccountManagement.vue')
          },
          {
            path: 'scheduled',
            name: 'scheduled.index',
            component: () => import('@/components/staff/recurring/RecurringGiftsList.vue')
          },
          {
            path: 'scheduled/:id',
            name: 'scheduled',
            component: () => import('@/components/staff/recurring/RecurringGiftDetails.vue'),
            props: true
          },
          {
            path: 'gifts',
            name: 'gifts.index',
            component: () => import('@/components/staff/transactions/TransactionsList.vue')
          },
          {
            path: 'givers',
            name: 'givers.index',
            component: () => import('@/components/staff/givers/GiversList.vue')
          },
          {
            path: 'giver/:ind_id',
            name: 'giver',
            component: () => import('@/components/staff/givers/GiverDetails.vue'),
            props: true
          },
          {
            path: 'users',
            name: 'users.index',
            component: () => import('@/components/staff/staff-management/StaffList.vue')
          },
          {
            path: 'gift/:cont_id',
            name: 'transaction',
            component: () => import('@/components/staff/transactions/TransactionDetails.vue'),
            props: true
          },
          {
            path: 'giving-forms',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              }
            },
            children: [
              {
                path: 'edit',
                name: 'giving-forms.editor',
                component: () => import('@/components/staff/giving-editor/GivingEditor.vue')
              }
            ]
          },
          {
            path: 'settings',
            name: 'settingsorg',
            component: () => import('@/components/staff/settings/MerchantOrgSettings.vue')
          }
        ]
      },
      {
        path: 'login',
        name: 'login.index',
        component: () => import('@/views/staff/account/LoginView.vue'),
        meta: {
          authRequired: false
        },
        beforeEnter: async (to, from, next) => {
          const sessionStore = stores.useSessionStore()
          const memberId = sessionStore.getMemberId
          const isAuthenticated = sessionStore.isAuthenticated
          const webId = to.params.webId
          if (!memberId && isAuthenticated) {
            next({ name: 'dashboard.index', params: { webId } })
          } else if (memberId && isAuthenticated) {
            next({ name: 'member.index', params: { webId } })
          } else {
            next()
          }
        }
      },
      {
        path: 'forgot/:username',
        name: 'forgot.password',
        component: () => import('@/views/staff/account/ForgotPasswordView.vue'),
        props: true,
        meta: {
          authRequired: false
        }
      },
      {
        path: 'resetpassword/:token',
        name: 'resetpassword',
        component: () => import('@/components/password/ResetPassword.vue'),
        props: true,
        meta: {
          authRequired: false
        }
      },
      {
        path: 'giving/',
        component: {
          render() {
            return h(GivingTransitionRouter)
          }
        },
        meta: {
          authRequired: true,
          isMemberPage: true
        },
        children: [
          {
            path: '',
            name: 'giving',
            component: () => import('@/components/giving/GivingCard.vue'),
            beforeEnter: async (to, from, next) => {
              const memberId = stores.useSessionStore().getMemberId
              const isAuthenticated = stores.useSessionStore().isAuthenticated
              const webId = to.params.webId
              if (!memberId && isAuthenticated) {
                next({ name: 'dashboard.index', params: { webId } })
              } else if (!memberId && !isAuthenticated) {
                next({ name: 'home', params: { webId } })
              } else {
                next()
              }
            }
          },
          {
            path: 'billing',
            name: 'billing',
            component: () => import('@/components/giving/BillingInfo.vue'),
            props: true,
            meta: {
              authRequired: true,
              isMemberPage: true
            }
          },
          {
            path: 'checkout',
            name: 'checkout',
            component: () => import('@/components/giving/PaymentForm.vue'),
            props: true,
            meta: {
              authRequired: true,
              isMemberPage: true
            }
          },
          {
            path: 'thanks',
            name: 'thanks',
            component: () => import('@/components/giving/GivingCompleteContainer.vue'),
            props: true,
            meta: {
              authRequired: true,
              isMemberPage: true
            }
          }
        ]
      },
      {
        path: 'mobile-giving/',
        component: {
          render() {
            return h(SkMobileGivingTransitionRouter)
          }
        },
        meta: {
          authRequired: false
        },
        children: [
          {
            path: '',
            name: 'mobile-giving',
            component: () => import('@/components/giving/text-to-give/SkMobileGivingForm.vue')
          },
          {
            path: '',
            name: 'expired-session',
            component: () => import('@/components/giving/text-to-give/SkExpiredMobileSession.vue')
          }
        ]
      }
    ]
  },
  {
    // path: '*',
    path: '/404',
    name: '404Page',
    component: () => import('@/views/errors/Error404PageView.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    // path: '*',
    path: '/:webId/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/errors/Error404PageView.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/:webId/unverified',
    name: 'unverified',
    component: () => import('@/views/errors/UnverifiedView.vue'),
    meta: {
      authRequired: false,
      isMemberPage: true
    }
  }
]
