<template>
  <v-dialog v-model="changelogDialogOpen" :persistent="true" :scrollable="true">
    <v-toolbar color="primary">
      <v-toolbar-title>Changelog</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon color="button" @click="changelogDialogOpen = false">
        <v-icon class="mdi mdi-close-circle img-close-icon" style="font-size: 1.5em" aria-hidden="true"></v-icon>
      </v-btn>
    </v-toolbar>
    <div class="changelog">
      <VueShowdown :markdown="changelog" />
    </div>
  </v-dialog>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { VueShowdown } from 'vue-showdown'
import { useFetch } from '@vueuse/core/index'

const changelog = ref(null)

// PROPS
const props = defineProps({
  isChangelogDialogOpen: {
    type: Boolean
  }
})

// EMITS
const emit = defineEmits(['update:isChangelogDialogOpen'])

// COMPUTED
const changelogDialogOpen = computed({
  get() {
    return props.isChangelogDialogOpen
  },
  set(value) {
    emit('update:isChangelogDialogOpen', value)
  }
})

// LIFECYCLE
onBeforeMount(async () => {
  const { data } = await useFetch('/CHANGELOG.md')
  changelog.value = data.value
})
</script>

<style scoped lang="scss">
:deep(.v-overlay__content) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.changelog {
  padding: 4rem;
  max-height: 75vh;
  width: 100%;
  overflow-y: auto;
  background: white;

  :deep(h1) {
    font-size: 1.5rem !important;
    margin-bottom: 1rem;
    padding-top: 0.75rem;
  }

  :deep(h2) {
    font-size: 1.25rem !important;
    margin-bottom: 0.75rem;
    padding-top: 0.75rem;
  }

  :deep(h3) {
    font-size: 1.1rem !important;
    margin-bottom: 0.75rem;
    padding-top: 0.75rem;
  }
}
</style>
