<template>
  <div class="Vue-Toastification__toast--warning">
    <div class="toast-content">{{ $t('login.emailNotVerified') }}</div>
    <div class="resend-button d-flex justify-end">
      <button
        v-if="!emailVerificationResent"
        class="v-btn v-btn--elevated v-theme--light bg-blue v-btn--density-default v-btn--size-default v-btn--variant-elevated secondary sk-btn mr-4 mt-3"
        @click.stop="onResendClick"
      >
        <span class="v-btn__content">{{ $t('login.resendEmailVerification') }}</span>
      </button>
    </div>
    <div v-if="emailVerificationResent">{{ $t('login.emailVerificationResent') }}</div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import stores from '@/stores'

const emailVerificationResent = ref(false)

// METHODS
const onResendClick = async () => {
  await stores.useSessionStore().resendGiverEmailVerification(localStorage.getItem('username'))
  emailVerificationResent.value = true
}
</script>

<style lang="scss" scoped>
.toast-content {
  color: #666 !important;
}

.resend-button {
  display: flex;
  justify-content: start;
  align-content: center;

  button {
    background-color: #666 !important;
    font-weight: 500;
    text-transform: none;
  }
}
</style>
