export default () => {
  const isDevMode = import.meta.env.VITE_ENV === 'development'
  const showConsoleLogInDevMode = (message, data) => {
    if (isDevMode) {
      console.log(message, data)
    }
  }

  return {
    showConsoleLogInDevMode
  }
}
