import Proxy from './Proxy'

export default class GivingProxy extends Proxy {
  /**
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super(`${import.meta.env.VITE_GIVING_API_BASE_URL}/v1`, parameters)
  }

  /**
   * * Get all forms for webId
   *
   * @param payload
   * @returns {Promise}
   */
  async getGivingFormByFormId(payload) {
    return this.submit('get', `${this.endpoint}/forms/${payload.webId}/${payload.formId}`)
  }

  /**
   * * Get the merchant profile data
   *
   * @param webId
   * @returns {Promise}
   */
  async getMerchantData(webId) {
    return this.submit('get', `${this.endpoint}/merchants/${webId}/webid`)
  }

  /**
   * * Update Merchant Data
   *
   * @param payload
   * @returns {Promise}
   */
  async updateMerchantData(payload) {
    return this.submit('patch', `${this.endpoint}/merchants/${payload.merchantId}`, payload)
  }

  /**
   * * Get the default form for the web id
   *
   * @param webId
   * @returns {Promise}
   */
  async getGivingFormByWebId(webId) {
    return this.submit('get', `${this.endpoint}/forms/${webId}`)
  }

  /**
   * * Update a form based on id.
   *
   * @param form
   * @returns {Promise}
   */
  async createGivingForm(form) {
    return this.submit('post', `${this.endpoint}/forms`, form)
  }

  /**
   * * Update a form based on id.
   *
   * @param form
   * @returns {Promise}
   */
  async updateGivingForm(form) {
    return this.submit('patch', `${this.endpoint}/forms/${form.id}`, form)
  }

  async updatePaymentMethod(payload) {
    return this.submit('patch', `${this.endpoint}/tokens/${payload.tokenId}`, payload)
  }

  /**
   * deletePaymentMethod
   * Refactored to call the giving-API-Payments-API Route
   * Notice the endpoint is different and points to /payments/token/
   * @param {*} payload
   * @returns
   */
  async deletePaymentMethod(payload) {
    return this.submit(
      'delete',
      `${this.endpoint}/payments/token/${payload.tokenId}/giver/${payload.giverId}/merchant/${payload.merchantId}`,
      payload
    )
  }

  /**
   * updatePaymentMethodAsDefault
   */
  async updatePaymentMethodAsDefault(payload) {
    return this.submit('post', `${this.endpoint}/tokens/default`, payload)
  }

  /**
   * Check for a profile based on the email address.
   * The endpoint for this is part of giving API, not the CHMS-API
   */
  async checkProfile(payload) {
    return this.submit('post', `${this.endpoint}/profile/member/checkprofile`, payload)
  }

  /**
   * Giver data member
   * The endpoint for this is part of giving API, not the CHMS-API
   */
  async getProfileGiverRecord(payload) {
    const merchantId = payload.merchantId
    return this.submit('get', `${this.endpoint}/givers/profile/${merchantId}`)
  }

  async getGiversByWebId(webId, year) {
    return this.submit('get', `${this.endpoint}/givers/list/${webId}/${year}`)
  }

  /**
   * @param {object} payload
   * Gets the giver record by ind id and merchant id
   */
  async getMobileProfileGiverRecordSkIndId(payload) {
    const { ind_id, merchantId } = payload
    return this.submit('get', `${this.endpoint}/givers/mobilegiving/${merchantId}/${ind_id}`)
  }

  /**
   * @param {object} payload
   * Gets the giver record by giverId and merchant id
   */
  async getMobileProfileGiverRecordGiverId(payload) {
    const { giverId, merchantId } = payload
    return this.submit('get', `${this.endpoint}/givers/mobilegiving/${merchantId}/giverId/${giverId}`)
  }

  /**
   * Profile States
   * The endpoint gathers states from csreftbl
   */
  async getProfileAttributeStates(webid) {
    return this.submit('get', `${this.endpoint}/profile/attribute/states/${webid}`)
  }

  /**
   * Upload images
   * The endpoint saves the file to S3 and returns the url from the bucket
   *
   *
   *    * @param formData
   *    * @param webId
   *    * @param bucket (bucket name)
   *    * @param uploadType (many/one)
   *    * @param hasAcl (true/false)
   *    * @param isPublic (boolean)
   *    * @returns {Promise}
   *
   */
  async uploadImageToS3(formData, webId, bucket, uploadType, hasAcl, isPublic) {
    const acl = isPublic && hasAcl ? 'public' : 'private'

    return this.submit(
      'post',
      `${this.endpoint}/files/${webId}/upload-asset/${bucket}/${uploadType}/${acl}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  }

  /**
   * Verify the token the user provided is valid before they can use the T2G UI
   * @param payload
   * @returns {Promise<unknown>}
   */
  async verifySmsSession(payload) {
    const { phoneNumber, authToken } = payload
    return this.submit('get', `${this.endpoint}/mobilegiving/checkSmsToken/${phoneNumber.trim()}/${authToken.trim()}`)
  }

  /**
   * resend email verification
   * @param webId
   * @param email
   * @returns {Promise<unknown>}
   */
  async resendGiverEmailVerification(webId, email) {
    return this.submit('get', `${this.endpoint}/profile/${webId}/emailverify/${email}`)
  }

  /**
   * resend email verification
   * @param email
   * @param token
   * @param webId
   * @returns {Promise<unknown>}
   */
  async verifyEmail(email, token, webId) {
    return this.submit('post', `${this.endpoint}/profile/${webId}/validate/${email}`, { token })
  }
}
