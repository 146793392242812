import { createRouter, createWebHistory } from 'vue-router'
import useConsoleLogHelper from '@/helpers/consoleLogHelper'
import routes from './routes'
import stores from '@/stores'
import { toRaw } from 'vue'

const { showConsoleLogInDevMode } = useConsoleLogHelper()

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes
})

const redirectBlacklist = ['login']

// Error handlers to deal with errors on new releases
router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location = to.fullPath
  }
})
router.onError((error, to) => {
  if (error.message.includes('Unable to preload CSS for')) {
    window.location = to.fullPath
  }
})

router.beforeEach(async (to, from, next) => {
  const userStore = stores.useUserStore()
  /*
		if we're headed to mobile giving, make sure we clear the stores to prevent pollution from other session types.
		sessionStore.memberId is only set for members. memberId is created for OG Guest when adding payment method and
		creating mobile giver profile.
	*/
  if (to.name === 'mobile-giving') {
    // we dont want non-T2G sessions trying to use T2G, so we log out each time the page is loaded.
    // If it's a real T2G session, the user will be logged back in and we save the necessary user data to preload the form
    let tmpGiver
    let tmpProfileData
    let tmpPaymentMethodData
    const performanceTiming = performance.getEntriesByType('navigation')[0]

    if (performanceTiming.type === 'reload' && userStore.giverRecordData?.giverId && to.query?.phid) {
      tmpGiver = structuredClone(toRaw(userStore.giverRecordData))
      tmpProfileData = structuredClone(toRaw(userStore.profileData))
      tmpPaymentMethodData = structuredClone(toRaw(userStore.paymentMethodData))
    }
    await stores.useSessionStore().logout()

    if (tmpGiver) {
      userStore.giverRecordData = tmpGiver
      userStore.profileData = tmpProfileData
      userStore.paymentMethodData = tmpPaymentMethodData
    }
  }

  const sessionStore = stores.useSessionStore()
  const organizationStore = stores.useOrganizationStore()
  const webId = to.params?.webId
  const orgName = organizationStore.getOrgName

  if (webId) {
    // the current web ID does not match the webId in the org store.
    // We need to clear out the stores/ log out the user so the wrong data isn't pulled
    if (webId && organizationStore.webId && webId !== organizationStore.webId) {
      await sessionStore.logout()
    }

    if (to.name === 'mobile-giving' && !to.query?.phid) {
      return next({ path: `/${webId}` })
    }

    organizationStore.webId = webId
    if (to.name !== 'NotFound' && to.name !== '404Page' && !orgName) {
      // if we don't find an org name, 404.
      const isWebIdFound = await organizationStore.fetchOrgName({ webId: webId })
      if (!isWebIdFound.success) {
        return next({ path: '/404' })
      }
    }
  }

  if (to.name === 'NotFound' || to.name === '404Page') {
    return next()
  }

  // in case we redirect to our self and not on the homepage
  if (to.fullPath === from.fullPath && to.fullPath !== '/') {
    showConsoleLogInDevMode('canceling redundant nav', to.fullPath, from.fullPath)
    return next(false)
  }

  if (to.fullPath.includes('/member') || to.name === 'home') {
    const isVerified = await organizationStore.getIsMerchantVerified(organizationStore.webId)
    if (!isVerified) {
      return next({ path: `/${webId}/unverified` })
    }
  }

  // if the route is marked auth only
  const authRequired = to.matched.some((route) => route.meta.authRequired)
  showConsoleLogInDevMode('[router] auth required', authRequired)
  // if no auth required...
  if (!authRequired) {
    showConsoleLogInDevMode('[router] no auth required. continue...')
    return next()
  }

  // startup
  if (!sessionStore.isInitialized) {
    showConsoleLogInDevMode('[router] initializing app')
    await sessionStore.start()
  }

  // if logged in
  if (sessionStore.isAuthenticated) {
    showConsoleLogInDevMode('[router] auth required and signed in. continue...')

    // if the user is logged in, but the timer hasn't been initialized, initialize it
    // the user probably did a hard refresh
    if (!stores.useLogoutTimerStore().getTimeoutId) {
      stores.useLogoutTimerStore().initialize()
    }

    return next()
  }

  if (redirectBlacklist.includes(webId)) {
    return next({ name: 'login.index' })
  }

  let path = 'login'
  if (webId && !to.fullPath.includes('/member')) {
    path = `/${webId}/login`
  }
  if (webId && to.fullPath.includes('/member')) {
    path = `/${webId}/`
  }
  showConsoleLogInDevMode('[router] auth required and not signed in. to login...', path)
  return next({ path, query: { redirect: to.fullPath } })
})

/* Vue Router is not meant to handle absolute urls. */
/* So whenever we want to deal with those, we can use this.$router.absUrl(url) */
/* see https://stackoverflow.com/questions/40015037/can-vue-router-open-a-link-in-a-new-tab */
router.absUrl = (url, newTab = true) => {
  const link = document.createElement('a')
  link.href = url
  link.target = newTab ? '_blank' : ''
  if (newTab) link.rel = 'noopener noreferrer' // IMPORTANT to add this
  link.click()
}

export default router
