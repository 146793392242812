/* ============
 * Getters for the user module
 * ============
 */

export default {
  getProfileData: (state) => state.profileData,
  getOriginalProfileData: (state) => state.originalProfileData,

  getFirstName(state) {
    try {
      return state.profileData.first_name
    } catch (error) {
      return ''
    }
  },

  getLastName(state) {
    try {
      return state.profileData.last_name
    } catch (error) {
      return ''
    }
  },

  getEmail(state) {
    try {
      return state.profileData.portalEmail
    } catch (error) {
      return ''
    }
  },

  getFullName() {
    try {
      let first = this.getFirstName
      let last = this.getLastName
      let name = first
      if (last) {
        name = `${name} ${last}`
      }
      return name
    } catch (error) {
      return ''
    }
  },

  getInitials() {
    try {
      let first = this.getFirstName
      let last = this.getLastName
      let initials = first.charAt(0)
      if (last) {
        initials = initials.concat(last.charAt(0))
      }
      return initials
    } catch (error) {
      return ''
    }
  },

  getGiverRecordData(state) {
    return state.giverRecordData
  },

  getPaymentMethodData(state) {
    return state.paymentMethodData
  },

  // returns a single payment method object if it's "default"
  getDefaultPaymentMethod(state) {
    return state.paymentMethodData?.find((pm) => pm.default_token === true)
  },

  getActivePaymentMethods(state) {
    if (state.paymentMethodData?.length) {
      return state.paymentMethodData.filter((paymentMethod) => !!paymentMethod.active)
    } else {
      return []
    }
  },

  getStates(state) {
    return state.states
  },

  getUserProfileStates(state) {
    return state.userProfileStates
  },

  getUserTimezone(state) {
    return state.userTimezone
  }
}
