import RainforestProxy from '@/proxy/RainforestProxy'

export default {
  setBatchDate(date) {
    this.batchDepositData.selectedDeposit.batchDate = date
  },

  setPaymentMethodConfigId(paymentMethodConfigId) {
    this.paymentMethodComponentData.paymentMethodConfigId = paymentMethodConfigId
  },

  setPaymentMethodSessionKey(sessionKey) {
    this.paymentMethodComponentData.sessionKey = sessionKey
  },

  async createPaymentMethodConfig(payload) {
    const proxy = new RainforestProxy()
    try {
      const { data: response } = await proxy.createPaymentMethodConfig(payload)
      if (response.success) {
        return {
          success: true,
          data: response.data
        }
      }
      return {
        success: false
      }
    } catch (error) {
      return {
        success: false
      }
    }
  },

  async createPaymentMethodSession(payload) {
    const proxy = new RainforestProxy()
    try {
      const { data: response } = await proxy.createPaymentMethodSession(payload)
      if (response.success) {
        return {
          success: true,
          data: response.data
        }
      }
      return {
        success: false
      }
    } catch (error) {
      return {
        success: false
      }
    }
  },

  async savePaymentMethod(payload) {
    const proxy = new RainforestProxy()
    try {
      const response = await proxy.savePaymentMethod(payload)
      if (response.data.success) {
        return {
          success: true,
          data: response.data.data
        }
      }
      return {
        success: false
      }
    } catch (error) {
      return {
        success: false
      }
    }
  }
}
