import { loadFonts } from './webfontloader'
import vuetify from './vuetify'
import mdijs from './mdijs'
import i18n from '@/plugins/i18n'
import ampli from '@/plugins/amplitude'
import '@/plugins/axios'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import VueSanitize from 'vue-sanitize-directive'

const vueSanitizeOptions = {
  allowedTags: ['span'],
  nonBooleanAttributes: ['class']
}

export async function registerPlugins(app) {
  await loadFonts()
  app.use(vuetify)
  app.use(mdijs)
  app.use(i18n)
  app.use(VueDatePicker)
  app.use(ampli)
  app.use(VueSanitize, vueSanitizeOptions)
}
