import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import { PiniaSharedState } from 'pinia-shared-state'
import router from './router'
import { registerPlugins } from '@/plugins'
import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import App from './App.vue'
import * as Sentry from '@sentry/vue'
import { VueShowdownPlugin } from 'vue-showdown'

const app = createApp(App)

const currentEnv = import.meta.env.VITE_ENV

if (currentEnv === 'production' || import.meta.env.VITE_ENABLE_SENTRY === 'true') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENV,
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'Unable to preload CSS for', 'Load failed'],
    attachStacktrace: true,
    debug: currentEnv !== 'production',
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    tracesSampleRate: currentEnv === 'production' ? 0.25 : 1.0,

    // Capture Replay for 0% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: currentEnv === 'production' ? 0.0 : 1.0,
    replaysOnErrorSampleRate: 1.0
  })
}

const pinia = createPinia()
pinia.use(({ store }) => {
  store.router = markRaw(router)
})

// to ensure store is persisted when browser is refreshed
pinia.use(piniaPluginPersistedState)
// to ensure store synchronization across tabs
pinia.use(
  PiniaSharedState({
    // Enables the plugin for all stores.
    enable: true,
    initialize: false
  })
)

const toastOptions = {
  position: POSITION.BOTTOM_CENTER,
  timeout: false,
  shareAppContext: true
}

app.use(pinia)
app.use(router)
app.use(Toast, toastOptions)

app.use(VueShowdownPlugin, {
  options: {
    emoji: true
  }
})

registerPlugins(app).then(() => {
  app.mount('#app')
})
