<template>
  <div class="header-wrapper">
    <MemberAppHeader class="no-print" />
    <MemberNav class="no-print" />
    <ProfileEditDialog v-if="getProfileDialogState" />
    <v-main v-if="!getProfileDialogState">
      <slot />
    </v-main>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import stores from '@/stores'
import MemberNav from '@/components/nav/MemberNav.vue'
import MemberAppHeader from '@/components/header/MemberAppHeader.vue'
import ProfileEditDialog from '@/components/profile-edit-dialog/ProfileEditDialog.vue'

const getProfileDialogState = computed(() => {
  return stores.useUserStore().getProfileDialogState
})
</script>

<style lang="scss">
@import '@/styles/index.scss';

.slot-content {
  justify-content: center;
}

.v-toolbar .v-btn {
  text-transform: capitalize;
  font-size: 0.75em;
}

@media print {
  .no-print {
    display: none !important;
  }
}
</style>
