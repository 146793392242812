import { ampli } from '@/ampli'

ampli.load({
  environment: process.env.NODE_ENV,
  client: {
    apiKey: import.meta.env.VITE_AMPLITUDE_API_KEY
  }
})

export default ampli
