import { useRoute } from 'vue-router'
import { onMounted, ref, watch } from 'vue'

export const useSelectedStaffNav = () => {
  const route = useRoute()
  const selectedRoute = ref('')

  // WATCHERS
  watch(route, () => {
    updateSelectedNav()
  })

  const updateSelectedNav = () => {
    switch (route.name) {
      case 'scheduled':
      case 'scheduled.index':
        selectedRoute.value = 'scheduled'
        break
      case 'deposits.index':
      case 'deposit.batch':
        selectedRoute.value = 'deposits'
        break
      case 'accounts.index':
        selectedRoute.value = 'accounts'
        break
      case 'gifts.index':
      case 'transaction':
        selectedRoute.value = 'gifts'
        break
      case 'giver':
      case 'givers':
        selectedRoute.value = 'givers'
        break
      case 'giving-forms.index':
      case 'giving-forms.editor':
        selectedRoute.value = 'giving-forms'
        break
      case 'users.index':
        selectedRoute.value = 'users-list'
        break
      case 'giving-forms.share':
        selectedRoute.value = 'sharing'
        break
      case 'givers.index':
        selectedRoute.value = 'givers'
        break
      case 'settingsorg':
        selectedRoute.value = 'settingsorg'
        break
      default:
        selectedRoute.value = 'home'
    }
  }

  onMounted(() => {
    updateSelectedNav()
  })

  return { selectedRoute }
}
