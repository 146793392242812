import UnsplashBaseProxy from '@/proxy/UnsplashBaseProxy'

export default class UnsplashImagesProxy extends UnsplashBaseProxy {
  /**
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super(`${import.meta.env.VITE_UNSPLASH_API_URL}`, parameters)
  }

  /**
   * search images
   * @param payload
   * @returns {Promise}
   */
  async searchPhotos(payload) {
    this.showConsoleLogInDevMode('[UnsplashImagesProxy] searchPhotos() payload: ', payload)

    const constructedPayload = {
      query: '',
      page: 1,
      perPage: 5,
      orderBy: 'relevant',
      orientation: 'landscape',
      contentFilter: 'high',
      ...payload
    }

    return this.submit('post', `${this.endpoint}`, constructedPayload)
  }

  /**
   * track "download" for image
   * @param payload
   * @returns {Promise}
   */
  async trackDownload(payload) {
    this.showConsoleLogInDevMode('[UnsplashImagesProxy] trackDownload() payload: ', payload)

    const constructedPayload = {
      action: 'trackDownload',
      links: payload
    }

    return this.submit('post', `${this.endpoint}`, constructedPayload)
  }
}
