import stores from '@/stores'

export default {
  async handleInactive() {
    const logoutRoute = this.router.currentRoute.value.fullPath.includes('member') ? 'member.login' : 'login.index'
    await stores.useSessionStore().logout()

    this.killTimer()
    this.router.replace({ name: logoutRoute, query: { redirect: this.router.currentRoute.value.fullPath } })

    return true
  },

  // remove the timer and all listeners so this doesn't run when users are logged out
  killTimer() {
    window.clearTimeout(this.getTimeoutId)
    // remove the timer
    this.timeoutId = null
    // remove handlers
    document.removeEventListener('keypress', this.resetTimer, false)
    document.removeEventListener('mousemove', this.resetTimer, false)
    document.removeEventListener('mousedown', this.resetTimer, false)
    document.removeEventListener('touchmove', this.resetTimer, false)
    document.addEventListener('onscroll', this.resetTimer, false)
  },

  startTimer() {
    // setTimeout returns an ID (can be used to start or clear a timer)
    this.timeoutId = window.setTimeout(this.handleInactive, this.getTimeoutInMS)
  },

  resetTimer() {
    window.clearTimeout(this.getTimeoutId)
    this.startTimer()
  },

  initialize() {
    document.addEventListener('keypress', this.resetTimer, false)
    document.addEventListener('mousemove', this.resetTimer, false)
    document.addEventListener('mousedown', this.resetTimer, false)
    document.addEventListener('touchmove', this.resetTimer, false)
    document.addEventListener('onscroll', this.resetTimer, false)
    this.startTimer()
  }
}
