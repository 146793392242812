<template>
  <v-navigation-drawer dark prominent>
    <v-list density="compact">
      <v-list-item
        :to="{ name: 'dashboard.index' }"
        prepend-icon="home"
        :title="$t('navigation.staff.dashboard')"
        color="button"
        :active="selectedNav === 'home'"
      />
      <v-list-item
        :to="{ name: 'gifts.index' }"
        prepend-icon="wallet_giftcard"
        :title="$t('navigation.staff.gifts')"
        color="button"
        :active="selectedNav === 'gifts'"
      />
      <v-list-item
        :to="{ name: 'scheduled.index' }"
        prepend-icon="autorenew"
        :title="$t('navigation.staff.recurring')"
        color="button"
        :active="selectedNav === 'scheduled'"
      />
      <v-list-item
        :to="{ name: 'givers.index' }"
        prepend-icon="group"
        :title="$t('navigation.staff.givers')"
        color="button"
        :active="selectedNav === 'givers'"
      />
      <v-list-item
        :to="{ name: 'deposits.index' }"
        prepend-icon="money"
        :title="$t('navigation.staff.deposits')"
        color="button"
        :active="selectedNav === 'deposits'"
      />
      <v-list-item
        :to="{ name: 'accounts.index' }"
        prepend-icon="account_balance"
        :title="$t('navigation.staff.accounts')"
        color="button"
        :active="selectedNav === 'accounts'"
      />
      <v-list-item
        :to="{ name: 'giving-forms.editor' }"
        prepend-icon="dashboard_customize"
        :title="$t('navigation.staff.givingForm')"
        color="button"
        :active="selectedNav === 'giving-forms'"
      />
      <v-list-item
        :to="{ name: 'users.index' }"
        prepend-icon="group"
        :title="$t('navigation.staff.staffmanagement')"
        color="button"
        :active="selectedNav === 'users-list'"
      />
      <v-list-item
        :to="{ name: 'settingsorg' }"
        prepend-icon="settings"
        :title="$t('navigation.staff.settingsOrg')"
        color="button"
        :active="selectedNav === 'settingsorg'"
      />
    </v-list>
    <div class="app-version">v{{ appVersion }}</div>
  </v-navigation-drawer>
</template>

<script setup>
import { useSelectedStaffNav } from '@/composables/nav/useSelectedStaffNav'
import { computed } from 'vue'
import stores from '@/stores'

const { selectedRoute: selectedNav } = useSelectedStaffNav()

// COMPUTED
const appVersion = computed(() => stores.useAppStore().getVersion)
</script>

<style scoped lang="scss">
.app-version {
  font-size: 0.7rem;
  padding-top: 1rem;
  margin-left: 1.5rem;
}
</style>
