import dayjs from 'dayjs'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { RRule } from 'rrule'

dayjs.extend(dayOfYear)
dayjs.extend(timezone)
dayjs.extend(utc)

export default () => {
  const newRecurrencePattern = () => {
    // We want GMT (zero offset) time here
    const nextWeek = dayjs().add(1, 'week').utc()
    return createRRuleString(nextWeek, undefined, 1)
  }

  const startDate = (ruleString) => {
    // We want user time here
    return dayjs.utc(RRule.fromString(ruleString).options.dtstart)
  }

  const previousTransactionDate = (rule) => {
    const rrule = createRRuleFromRecurrencyString(rule),
      // We want user time here
      today = dayjs().utc().toDate()
    return rrule.before(today)
  }

  const nextTransactionDate = (rule) => {
    const rrule = createRRuleFromRecurrencyString(rule)
    const today = dayjs().utc().startOf('date').toDate()

    return rrule.after(today) || today
  }

  const finalTransactionDate = (rule) => {
    const rrule = createRRuleFromRecurrencyString(rule)
    const hasCount = !!rrule.options.count
    const hasUntil = !!rrule.options.until
    const hasEnding = hasCount || hasUntil
    let dates = []
    hasEnding &&
      (dates = rrule
        .all()
        // We want user time here
        .map((date) => dayjs(date).utc()))
    const finalDate = dates[dates.length - 1]
    return finalDate ? finalDate : undefined
  }

  const endTransactionDate = (rule) => {
    const rRule = createRRuleFromRecurrencyString(rule)
    const until = rRule.options.until
    // We want user time here
    return until ? dayjs(rRule.options.until).utc() : undefined
  }

  const frequencyFromRule = (rule) => {
    const rrule = createRRuleFromRecurrencyString(rule)
    return RRule.FREQUENCIES[rrule.options.freq]
  }

  // USED TO SET THE CHIP INDEX
  const numericFrequency = (rule) => {
    const rrule = createRRuleFromRecurrencyString(rule)
    switch (rrule.options.freq) {
      case 0: // yearly
        return 3 // CHIP YEARLY INDEX
      case 1: //MONTHLY'
        return 2 // CHIP 'MONTHLY' INDEX
      case 2: // CHIP 'WEEKLY' or 'SEMI_MONTHLY' INDEX DEPENDING ON THE INTERVAL
        return rrule.options.interval === 2 ? 1 : 0
    }
  }

  const byWeekDayFromRule = (rule) => {
    const rrule = createRRuleFromRecurrencyString(rule)
    return rrule.origOptions.byweekday[0]
  }

  const transactionPatternText = (rule) => {
    return createRRuleFromRecurrencyString(rule).toText()
  }

  const getRRuleWeekDay = (d) => {
    // fixes conflict with rrule where MONDAY=0 and dayjs(start).day() SUNDAY = 0
    switch (dayjs(d).utc().day()) {
      case 0:
        return RRule.SU
      case 1:
        return RRule.MO
      case 2:
        return RRule.TU
      case 3:
        return RRule.WE
      case 4:
        return RRule.TH
      case 5:
        return RRule.FR
      case 6:
        return RRule.SA
    }
  }

  const createRRuleString = (start, end, frequency) => {
    let interval
    let freq

    const weekDay = getRRuleWeekDay(start) // rrule MONDAY=0
    const monthDay = dayjs(start).utc().date()
    const yearDay = dayjs(start).utc().dayOfYear()

    switch (frequency) {
      case 0:
        interval = 1
        freq = RRule.WEEKLY
        break
      case 1:
        interval = 2 // every 2 weeks
        freq = RRule.WEEKLY
        break
      case 2:
        interval = 1
        freq = RRule.MONTHLY
        break
      case 3:
        interval = 1
        freq = RRule.YEARLY
        break
    }

    const rule = new RRule({
      freq,
      interval,
      byweekday: freq === RRule.WEEKLY ? weekDay : undefined,
      bymonthday: freq === RRule.MONTHLY ? monthDay : undefined,
      byyearday: freq === RRule.YEARLY ? yearDay : undefined,
      dtstart: start.utc().toDate(),
      until: end ? end.utc().toDate() : undefined
    })

    return rule.toString()
  }

  const createRRuleFromRecurrencyString = (ruleString) => {
    let rrule
    try {
      rrule = RRule.fromString(ruleString)
    } catch (err) {
      throw {
        message: 'Invalid rule string',
        data: ruleString
      }
    }

    return rrule
  }

  return {
    byWeekDayFromRule,
    createRRuleString,
    createRRuleFromRecurrencyString,
    endTransactionDate,
    finalTransactionDate,
    frequencyFromRule,
    getRRuleWeekDay,
    newRecurrencePattern,
    nextTransactionDate,
    numericFrequency,
    previousTransactionDate,
    startDate,
    transactionPatternText
  }
}
