import stores from '@/stores'
import sentryErrorLoggingHelper from '@/helpers/sentryErrorLoggingHelper'
import transactionHelpers from '@/helpers/transactionsHelper'
import TransactionsProxy from '@/proxy/TransactionsProxy'
import { paymentConstants } from '@/constants'

export default {
  /**
   * get all transactions for Individual
   *
   * @returns {Promise<void>}
   */
  async getIndividualTransactionsData() {
    const result = {
      success: false,
      error: null
    }
    const proxy = new TransactionsProxy()

    let response = null
    try {
      response = await proxy.getIndTransactions()

      if (response) {
        this.transactions = response.data.map((transaction) => {
          return {
            ...transaction,
            batch_dt: transaction.batch_dt ? Date.parse(transaction.batch_dt) : null
          }
        })
        result.success = true
      } else {
        result.error = true
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      result.error = error
    }

    return Promise.resolve(result)
  },

  /**
   * get all transactions for Individual
   *
   * @returns {Promise<void>}
   */
  async getIndividualGridTransactionData() {
    const result = {
      success: false,
      error: null
    }
    const proxy = new TransactionsProxy()

    let response = null
    const payload = {
      ind_id: stores.useSessionStore().getMemberId
    }

    try {
      response = await proxy.getIndGridTransactions(payload)

      if (response) {
        const { getTransactionState } = transactionHelpers()
        this.gridTransactions = response.data.map((transaction) => {
          const paymentSystemType =
            transaction.csgivingtransaction?.payment_type === paymentConstants.CS_GIVING_TRANSACTION_PAYMENT_TYPES.CHECK
              ? paymentConstants.PAYMENT_TYPES.ACH
              : paymentConstants.PAYMENT_TYPES.CREDITCARD
          const returnId = transaction.csgivingtransaction?.return_id
          const status = transaction.csgivingtransaction?.status

          const transactionState = getTransactionState(paymentSystemType, status, returnId)
          return {
            ...transaction,
            batch_dt: transaction.batch_dt || null,
            transactionState
          }
        })
        result.success = true
      } else {
        result.error = true
      }
    } catch (error) {
      result.error = error
    }

    return Promise.resolve(result)
  },

  /**
   * get transactions for the individual statement page
   *
   * @returns {Promise<void>}
   */
  async getIndividualStatementTransactionData(data, individualId) {
    const result = {
      success: false,
      error: null
    }
    const proxy = new TransactionsProxy()
    let response = null

    // TODO - Validate the data here before we pass it to the proxy
    try {
      response = await proxy.getFilteredTransactions(data, individualId)
      if (response) {
        this.statementTransactions = response.data
        this.createStatementTransactionsWithFeesApplied(structuredClone(response.data))
        result.success = true
      } else {
        result.error = true
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      result.error = error
    }

    return Promise.resolve(result)
  },

  /**
   * get transactions for the individual statement page with fees applied to the line items
   * @param {object} transactions
   */
  createStatementTransactionsWithFeesApplied(transactions) {
    const groupedByContId = new Map()

    // Aggregate total gift amounts and identify transactions needing fee updates
    transactions.forEach(({ cont_id, amt, user_paid_fee, fee }) => {
      let group = groupedByContId.get(cont_id)
      if (!group) {
        group = { totalGift: 0, feeTransactions: [] }
        groupedByContId.set(cont_id, group)
      }
      group.totalGift += amt

      if (user_paid_fee === 1 && fee != null) {
        group.feeTransactions.push({ amt, fee })
      }
    })

    // Apply fee calculations
    transactions.forEach((transaction) => {
      const group = groupedByContId.get(transaction.cont_id)
      if (transaction.user_paid_fee === 1 && transaction.fee != null) {
        const splitFee = (transaction.amt / group.totalGift) * transaction.fee
        transaction.splitFee = splitFee
        transaction.amt += splitFee
      } else {
        transaction.splitFee = 0
      }
    })

    this.statementTransactionsWithFeesApplied = transactions
  },

  /**
   * get all recurring transactions for Org
   *
   * @returns {Promise<void>}
   */
  async getOrgRecurringTransactionsData() {
    const result = {
      success: false,
      error: null
    }
    const proxy = new TransactionsProxy()

    let response = null
    const payload = {
      webId: stores.useOrganizationStore().getWebId
    }
    try {
      response = await proxy.getOrgRecurringTransactions(payload)

      if (response) {
        this.recurringTransactions = response.data.map((transaction) => {
          return {
            ...transaction,
            batch_dt: transaction.contribDate ? Date.parse(transaction.contribDate) : null
          }
        })
        result.success = true
      } else {
        result.error = true
      }
    } catch (error) {
      result.error = error
    }

    return Promise.resolve(result)
  },

  /**
   * get one Recurring transaction for Org
   *
   * @returns {Promise<void>}
   */
  getOrgRecurringTransactionById(id) {
    return this.recurringTransactions.filter((recurringTransaction) => {
      return recurringTransaction.id === Number(id)
    })[0]
  },

  /**
   * get a single transaction from the trasnaction list
   *
   * @returns any
   */
  getTransactionById(transactionId) {
    return this.transactions.filter((transaction) => {
      return transaction.tran_id === transactionId
    })[0]
  },

  /**
   * update staff recurring gift
   * @param gift
   * @returns {Promise<void>}
   */
  async staffUpdateGift(gift) {
    const result = {
      success: false,
      error: null
    }
    const proxy = new TransactionsProxy()
    let response = null
    try {
      response = await proxy.updateRecurringGift(gift)

      if (response) {
        result.success = true
        await this.getOrgRecurringTransactionsData()
      } else {
        result.error = true
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      result.error = error
    }

    return result
  },

  /**
   * get a all GPI deposits
   *
   * @returns {Promise<void>}
   */
  async getDepositData() {
    const proxy = new TransactionsProxy()
    return await proxy.getAllDepositsByOrg()
  },

  /**
   * Get all deposits by webId, used by RF
   * @returns {Promise<void>}
   */
  async getRainforestDepositsByWebId() {
    const webId = stores.useOrganizationStore().getWebId
    const proxy = new TransactionsProxy()
    return await proxy.getDepositsByWebId(webId)
  },

  /**
   * get a single deposit
   *
   * @returns any
   */
  async getDepositDetailsByBatchNumber(batchNumber) {
    const proxy = new TransactionsProxy()
    return await proxy.getDepositDetailsByBatchNumber(batchNumber)
  },

  /**
   * get a single deposit
   *
   * @returns any
   */
  async getDepositDetailsByBatchDate(date) {
    const proxy = new TransactionsProxy()
    return await proxy.getDepositDetailsByBatchDate(date)
  },

  buildPaymentMethodFailureMessage(processorResponseCode) {
    switch (processorResponseCode) {
      case 473:
      case 474:
        return paymentConstants.GPI_ERROR_MESSAGES.AVS_CVV
      default:
        return paymentConstants.GPI_ERROR_MESSAGES.GENERIC
    }
  },

  /**
   * @description create a credit card token
   * @param {object} payload
   * @param {boolean} isMobileGiving
   * @returns {object}
   */
  async createCreditCardToken(payload, isMobileGiving) {
    const proxy = new TransactionsProxy()

    try {
      const response = isMobileGiving
        ? await proxy.createMobileGivingCreditCardToken(payload)
        : await proxy.createCreditCardToken(payload)

      return response.data
    } catch (error) {
      return {
        success: false,
        status: 500,
        message: 'Service is unavailable'
      }
    }
  },

  /**
   * @description create an ach token
   * @param {object} payload
   * @param {boolean} isMobileGiving
   * @returns {object}
   */
  async createBankAccountToken(payload, isMobileGiving) {
    const proxy = new TransactionsProxy()

    try {
      const response = isMobileGiving
        ? await proxy.createMobileGivingBankAccountToken(payload)
        : await proxy.createBankAccountToken(payload)

      return response.data
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      return {
        success: false,
        status: 500,
        message: 'Service is unavailable'
      }
    }
  },

  /**
   * @description process a transaction
   * @param {object} payload
   * @returns {object}
   */
  async processGivingTransaction(payload) {
    const proxy = new TransactionsProxy()

    const response = await proxy.processGivingTransaction(payload)

    return response.data
  },

  /**
   * get all transactions
   *
   * @returns {Promise<void>}
   */
  async getGridTransactionsData() {
    const result = {
      success: false,
      error: null
    }
    const proxy = new TransactionsProxy()

    let response = null
    try {
      response = await proxy.getGridTransactions()

      if (response) {
        const { getTransactionState } = transactionHelpers()
        this.gridTransactions = response.data.map((transaction) => {
          const paymentSystemType =
            transaction.csgivingtransaction?.payment_type === paymentConstants.CS_GIVING_TRANSACTION_PAYMENT_TYPES.CHECK
              ? paymentConstants.PAYMENT_TYPES.ACH
              : paymentConstants.PAYMENT_TYPES.CREDITCARD
          const returnId = transaction.csgivingtransaction?.return_id
          const status = transaction.csgivingtransaction?.status

          const transactionState = getTransactionState(paymentSystemType, status, returnId)

          return {
            ...transaction,
            batch_dt: transaction.batch_dt || null,
            transactionState
          }
        })
        result.success = true
      } else {
        result.error = true
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      result.error = error
    }

    return Promise.resolve(result)
  },

  /**
   * get transaction insight data
   *
   * @returns {Promise<void>}
   */
  async getInsightsTotalGifts() {
    const result = {
      success: false,
      error: null
    }
    const proxy = new TransactionsProxy()

    let response = null
    try {
      response = await proxy.getInsightsTotalGifts()

      if (response) {
        this.transactionInsightsTotalGifts = response.data.giftCount
        result.success = true
      } else {
        result.error = true
      }
    } catch (error) {
      result.error = error
    }

    return result
  },

  /**
   * get a single transaction from the trasnaction list
   *
   * @returns any
   */
  getGridTransactionById(contributionId) {
    return this.gridTransactions.find((transaction) => transaction.cont_id === contributionId) ?? null
  },

  /**
   * @description update the selected transaction details in both store and database in case database update fails,
   * the transaction status on the details page will be up to date so refund/void button can properly be enabled/disabled
   * @param {string} contributionId payload contribution ID from route params
   * @param {string} status status form response
   * @param {string} referenceId reference id of transaction from response
   * @param {string} returnId return ID from response
   * @returns
   */
  async updateSelectedTransactionDetails(contributionId, status, referenceId, returnId) {
    const index = this.gridTransactions.findIndex((transaction) => transaction.cont_id === contributionId)
    if (index !== -1) {
      this.gridTransactions[index].csgivingtransaction.status = status
      this.gridTransactions[index].csgivingtransaction.return_id = returnId
    }

    // TODO: Do not make this call for rainforest merchants and depend on webhooks instead. Issue in progress: https://servantkeeper.atlassian.net/browse/SKE-1629

    const proxy = new TransactionsProxy()
    const updateDbTransactionPayload = {
      status,
      referenceId,
      returnId
    }
    return await proxy.updateSelectedTransactionDetails(updateDbTransactionPayload, contributionId)
  },

  /**
   *
   * @param {object} payload
   {
   "referenceId": "MER-b93d576a-5a29-4b3d-9c9e-ab9ea7dbca41",
   "paymentType": "ACH",
   "merchantId": "1"
   }
   * @returns {Promise<void>}
   */
  async fetchTransactionDetails(payload) {
    let result = {
      success: false
    }
    let response = null
    try {
      const proxy = new TransactionsProxy()
      response = await proxy.fetchTransactionDetails(payload)
      if (response?.status === 200) {
        result = { ...response, success: true }
      } else if (response?.status === 204) {
        result = { ...response, success: false }
      } else if (response?.status === 400) {
        result = { ...response, success: false }
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
    return result
  },

  /**
   * refund a transaction
   * @param payload - the transaction data to be refunded
   */
  async refundTransaction(payload) {
    const paymentAPIRequest = {
      action_type: 'REFUND',
      amount: payload.amount,
      reference_id: payload.reference_id,
      merchantId: payload.merchantId,
      payment_type: payload.payment_type
    }
    let result = {
      success: false
    }
    let response = null
    try {
      const proxy = new TransactionsProxy()
      response = await proxy.refundTransaction(paymentAPIRequest)
      switch (response?.status) {
        case 200:
          result = { ...response.data, success: true }
          break
        case 204:
        case 207:
          result = { ...response, success: false }
          break
        default:
          result = { ...response, success: false }
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
    return result
  },

  /**
   * void a transaction
   * @param payload - the transaction data to void
   */
  async voidTransaction(payload) {
    const paymentAPIRequest = {
      action_type: 'VOID',
      amount: payload.amount,
      reference_id: payload.reference_id,
      merchantId: payload.merchantId,
      payment_type: payload.payment_type
    }
    let result = {
      success: false
    }
    let response = null
    try {
      const proxy = new TransactionsProxy()
      response = await proxy.voidTransaction(paymentAPIRequest)
      if (response?.data.success) {
        result = { ...response.data, success: true }
      } else {
        result = { ...response.data, success: false }
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
    return result
  }
}
