import Proxy from './Proxy'

export default class RecaptchaProxy extends Proxy {
  /**
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super(`${import.meta.env.VITE_GIVING_API_BASE_URL}/v1`, parameters)
  }

  /**
   * Validate the recaptcha token
   * @param {string} token
   * @returns {Promise}
   */
  async validateRecaptchaToken(token) {
    return this.submit('post', `${this.endpoint}/recaptcha/verify`, { token })
  }
}
