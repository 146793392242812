/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull skog-js'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 3
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/implementation/skog-js)
 */

import * as amplitude from '@amplitude/analytics-browser'

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [configuration]
 * @property {BrowserClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'servantkeeperonlinegivingdevelopment'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} servantkeeperonlinegivingdevelopment
 */
export const ApiKey = {
  servantkeeperonlinegivingdevelopment: ''
}

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration = {
  plan: {
    version: '3',
    branch: 'main',
    source: 'skog-js',
    versionId: 'e7696509-7973-4846-b2c1-ca008dd15e5e'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-javascript-ampli',
      sourceVersion: '2.0.0'
    }
  }
}

export class GiveNow {
  constructor(properties) {
    this.event_type = 'giveNow'
    this.event_properties = properties
  }
}

export class GiverLogIn {
  constructor(properties) {
    this.event_type = 'giverLogIn'
    this.event_properties = properties
  }
}

export class GivingCheckout {
  constructor(properties) {
    this.event_type = 'givingCheckout'
    this.event_properties = properties
  }
}

export class GivingCheckoutAbandoned {
  constructor(properties) {
    this.event_type = 'givingCheckoutAbandoned'
    this.event_properties = properties
  }
}

export class OnboardingAbandoned {
  constructor(properties) {
    this.event_type = 'onboardingAbandoned'
    this.event_properties = properties
  }
}

export class OnboardingSignupComplete {
  constructor(properties) {
    this.event_type = 'onboardingSignupComplete'
    this.event_properties = properties
  }
}

export class OnboardingSignupFailed {
  constructor(properties) {
    this.event_type = 'onboardingSignupFailed'
    this.event_properties = properties
  }
}

export class OnboardingSignupSubmitted {
  constructor(properties) {
    this.event_type = 'onboardingSignupSubmitted'
    this.event_properties = properties
  }
}

export class OnboardSignupInvalid {
  constructor(properties) {
    this.event_type = 'onboardSignupInvalid'
    this.event_properties = properties
  }
}

export class ProfileCheckLogin {
  constructor(properties) {
    this.event_type = 'profileCheckLogin'
    this.event_properties = properties
  }
}

export class ProfileCheckResetPassword {
  constructor(properties) {
    this.event_type = 'profileCheckResetPassword'
    this.event_properties = properties
  }
}

export class ProfileCheckResult {
  constructor(properties) {
    this.event_type = 'profileCheckResult'
    this.event_properties = properties
  }
}

export class ProfileCheckSearchAgain {
  constructor(properties) {
    this.event_type = 'profileCheckSearchAgain'
    this.event_properties = properties
  }
}

export class ProfileCheckSignup {
  constructor(properties) {
    this.event_type = 'profileCheckSignup'
    this.event_properties = properties
  }
}

export class SignInButtonClick {
  constructor(properties) {
    this.event_type = 'signInButtonClick'
    this.event_properties = properties
  }
}

export class StartOnboarding {
  constructor() {
    this.event_type = 'startOnboarding'
  }
}

export class StartProfileCheck {
  constructor(properties) {
    this.event_type = 'startProfileCheck'
    this.event_properties = properties
  }
}

/**
 * @typedef PromiseResult
 * @type {object}
 * @property {Promise|undefined} promise
 */

const getVoidPromiseResult = () => ({ promise: Promise.resolve() })

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {BrowserClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
  }

  /**
   * @return {BrowserClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param {LoadOptions} options Configuration options to initialize the Ampli SDK with. 'environment', 'client.apiKey' or 'client.instance' is required.
   *
   * @return {PromiseResult}
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey;
    if (options?.client?.apiKey) {
      apiKey = options.client.apiKey;
    } else if (options?.environment) {
      apiKey = ApiKey[options.environment];
    }

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user.
   *
   * @param {string|undefined} userId The user's id.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  identify(userId, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options, user_id: userId};
    }

    const ampIdentify = new amplitude.Identify();
    return this.amplitude.identify(ampIdentify, options);
  }

  /**
  * Flush the event.
  */
  flush() {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.flush();
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  track(event, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.track(event, undefined, options);
  }

  /**
   * giveNow
   *
   * [View in Tracking Plan](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/events/main/latest/giveNow)
   *
   * Event to track when a user clicks Give Now in online giving
   *
   * Owner: TJ Downes
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.email Property has no description in tracking plan.
   * @param {number} properties.total Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  giveNow(properties, options) {
    return this.track(new GiveNow(properties), options);
  }

  /**
   * giverLogIn
   *
   * [View in Tracking Plan](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/events/main/latest/giverLogIn)
   *
   * Event to track when a giver logs into the system
   *
   * Owner: TJ Downes
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.email Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  giverLogIn(properties, options) {
    return this.track(new GiverLogIn(properties), options);
  }

  /**
   * givingCheckout
   *
   * [View in Tracking Plan](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/events/main/latest/givingCheckout)
   *
   * Event to track when a giver completes the checkout process during an online giving transaction
   *
   * Owner: TJ Downes
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.email Property has no description in tracking plan.
   * @param {number} properties.feeAmount Property has no description in tracking plan.
   * @param {number} properties.total Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  givingCheckout(properties, options) {
    return this.track(new GivingCheckout(properties), options);
  }

  /**
   * givingCheckoutAbandoned
   *
   * [View in Tracking Plan](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/events/main/latest/givingCheckoutAbandoned)
   *
   * Event to track when a user abandons the checkout process while making a donation
   *
   * Owner: TJ Downes
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.email Property has no description in tracking plan.
   * @param {string} properties.feeAmount Property has no description in tracking plan.
   * @param {number} properties.total Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  givingCheckoutAbandoned(properties, options) {
    return this.track(new GivingCheckoutAbandoned(properties), options);
  }

  /**
   * onboardingAbandoned
   *
   * [View in Tracking Plan](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/events/main/latest/onboardingAbandoned)
   *
   * Event to track users who started but did not complete the onboarding process
   *
   * Owner: TJ Downes
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.email Property has no description in tracking plan.
   * @param {number} properties.step Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  onboardingAbandoned(properties, options) {
    return this.track(new OnboardingAbandoned(properties), options);
  }

  /**
   * onboardingSignupComplete
   *
   * [View in Tracking Plan](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/events/main/latest/onboardingSignupComplete)
   *
   * Event to track when a user successfully completes the onboarding signup process
   *
   * Owner: TJ Downes
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.email Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  onboardingSignupComplete(properties, options) {
    return this.track(new OnboardingSignupComplete(properties), options);
  }

  /**
   * onboardingSignupFailed
   *
   * [View in Tracking Plan](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/events/main/latest/onboardingSignupFailed)
   *
   * Event to track when a user's attempt to sign up during onboarding process fails
   *
   * Owner: TJ Downes
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.email Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  onboardingSignupFailed(properties, options) {
    return this.track(new OnboardingSignupFailed(properties), options);
  }

  /**
   * onboardingSignupSubmitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/events/main/latest/onboardingSignupSubmitted)
   *
   * Event to track when a user submits their information during the onboarding process
   *
   * Owner: TJ Downes
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.email Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  onboardingSignupSubmitted(properties, options) {
    return this.track(new OnboardingSignupSubmitted(properties), options);
  }

  /**
   * onboardSignupInvalid
   *
   * [View in Tracking Plan](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/events/main/latest/onboardSignupInvalid)
   *
   * Event to track when a user attempts to sign up for onboarding but encounters an error due to invalid information provided. This is caused by a failed Recaptcha.
   *
   * Owner: TJ Downes
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.email Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  onboardSignupInvalid(properties, options) {
    return this.track(new OnboardSignupInvalid(properties), options);
  }

  /**
   * profileCheckLogin
   *
   * [View in Tracking Plan](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/events/main/latest/profileCheckLogin)
   *
   * Event to track when a user has found their profile and has decided to log in
   *
   * Owner: TJ Downes
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.email Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  profileCheckLogin(properties, options) {
    return this.track(new ProfileCheckLogin(properties), options);
  }

  /**
   * profileCheckResetPassword
   *
   * [View in Tracking Plan](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/events/main/latest/profileCheckResetPassword)
   *
   * Event to track when a user has found an account and decided to reset their password
   *
   * Owner: TJ Downes
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.email Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  profileCheckResetPassword(properties, options) {
    return this.track(new ProfileCheckResetPassword(properties), options);
  }

  /**
   * profileCheckResult
   *
   * [View in Tracking Plan](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/events/main/latest/profileCheckResult)
   *
   * Event to track the result of a profile check for a user
   *
   * Owner: TJ Downes
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.email Property has no description in tracking plan.
   * @param {boolean} properties.profileFound Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  profileCheckResult(properties, options) {
    return this.track(new ProfileCheckResult(properties), options);
  }

  /**
   * profileCheckSearchAgain
   *
   * [View in Tracking Plan](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/events/main/latest/profileCheckSearchAgain)
   *
   * Event to track when a user performs a search again within the profile check feature
   *
   * Owner: TJ Downes
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.email Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  profileCheckSearchAgain(properties, options) {
    return this.track(new ProfileCheckSearchAgain(properties), options);
  }

  /**
   * profileCheckSignup
   *
   * [View in Tracking Plan](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/events/main/latest/profileCheckSignup)
   *
   * Event to track when a user has not found an exsiting profile and decides to sign up
   *
   * Owner: TJ Downes
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.email Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  profileCheckSignup(properties, options) {
    return this.track(new ProfileCheckSignup(properties), options);
  }

  /**
   * signInButtonClick
   *
   * [View in Tracking Plan](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/events/main/latest/signInButtonClick)
   *
   * Event to track when a user clicks the sign-in button
   *
   * Owner: TJ Downes
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.email Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  signInButtonClick(properties, options) {
    return this.track(new SignInButtonClick(properties), options);
  }

  /**
   * startOnboarding
   *
   * [View in Tracking Plan](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/events/main/latest/startOnboarding)
   *
   * Event to track when a user begins the onboarding process
   *
   * Owner: TJ Downes
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  startOnboarding(options) {
    return this.track(new StartOnboarding(), options);
  }

  /**
   * startProfileCheck
   *
   * [View in Tracking Plan](https://data.amplitude.com/servant-keeper/Servant%20Keeper%20Online%20Giving%20(Development)/events/main/latest/startProfileCheck)
   *
   * Event to track when a user begins the profile verification process during onboarding
   *
   * Owner: TJ Downes
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.email Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  startProfileCheck(properties, options) {
    return this.track(new StartProfileCheck(properties), options);
  }
}

export const ampli = new Ampli()
